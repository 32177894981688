import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../services/axiosIntercept";
import * as selectors from "../selectors/selectors";
import * as actions from "../actions/actions";
import { setIsLoadingVouchers } from "../actions/actions";

const useGetAnalytics = () => {
  const dispatch = useDispatch();

  dispatch(setIsLoadingVouchers(true));

  const getAnalytics = async (/** @type {string} */ loyaltyProgramId) => {
    try {
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}v1/analytics`,

          { programId: loyaltyProgramId },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
            },
          },
        )
        .then((res) => {
          console.log("Analytics response", res.data);
          dispatch(actions.setAnalytics(res.data));
          dispatch(setIsLoadingVouchers(false));
        });
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response.status, error.response.data);
      } else {
        console.error("Network Error:", error.message);
      }
      dispatch(setIsLoadingVouchers(false));
      throw error;
    }
  };

  return getAnalytics;
};

export default useGetAnalytics;
