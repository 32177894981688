import React from "react";
import "./a4_voucher.css";
import PoweredByMeed from "../PoweredByMeed/powered_by_meed";

function A4Voucher({ data }) {
  return (
    <div className="a4-voucher">
      <div
        className="a4-voucher__body"
        style={{ backgroundColor: "rgb(0 112 66)" }}
      >
        <div className="a4-voucher__head">
          <div className="a4-voucher__heading">Join {data.businessName}</div>
          <div className="a4-voucher__wizard">
            <div className="a4-voucher__tile a4-voucher__tile--scan">
              <div className="a4-voucher__illustration">
                <div className="a4-voucher__action">
                  Scan the QR code with your phone’s camera
                </div>
              </div>
            </div>
            <div className="a4-voucher__tile a4-voucher__tile--otp">
              <div className="a4-voucher__illustration">
                <div className="a4-voucher__action">
                  Use your phone number to get a one time password
                </div>
              </div>
            </div>
            <div className="a4-voucher__tile a4-voucher__tile--pass">
              <div className="a4-voucher__illustration">
                <div className="a4-voucher__action">
                  Remember to Add to your wallet
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="a4-voucher__main">
          <div className="a4-voucher__summary">
            <div className="a4-voucher__intro">Scan and Join</div>
            <div className="a4-voucher__logo">
              <img
                src={`${process.env.REACT_APP_CDN_URL}${data.businessLogo}.jpg`}
                alt="Business Logo"
              />
            </div>
          </div>
          <div className="a4-voucher__join">
            <img
              src={`${process.env.REACT_APP_QR_CODE_URL}${data.programUuid}`}
              alt="Business QR"
            />
          </div>
        </div>
        <div className="a4-voucher__offer">{data.offer}</div>
        <div className="a4-voucher__footer">
          <PoweredByMeed />
        </div>
      </div>
    </div>
  );
}

export default A4Voucher;
