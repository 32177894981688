import axiosInstance from "./axiosIntercept";
const apiUrl = `${process.env.REACT_APP_API_URL}v1/program`;

export const fetchProgramsByAccount = async (accountUuid) => {
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_API_URL}v1/program/account/${accountUuid}`,
    {
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    },
  );
  // console.log("Programs:", response.data);
  // console.log("ID:", id);
  // return response.data.filter((data) => data.merchantId === id);
  return response.data;
};

export const createUpdateProgram = async (programData) => {
  try {
    const response = await axiosInstance.post(apiUrl, programData, {
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating /updating program:",
      error.response || error.message,
    );
    throw error;
  }
};
