import React from "react";
import "./loading_spinner.css";
import meedlogo from "../../assets/icons/meed logo-vertical orange.svg";

function LoadingSpinner() {
  return (
    <div className="loading-spinner">
      <div className="loading-spinner__widget">
        <div className="loading-spinner__illustration">
          <div className="loading-spinner__outer-ring"></div>
          <div className="loading-spinner__inner-ring"></div>
          <img
            className="loading-spinner__logo"
            src={meedlogo}
            width="74"
            alt="Meed logo"
          />
        </div>
        <div className="loading-spinner__label">Loading</div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
