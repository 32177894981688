import axiosInstance from "./axiosIntercept";

// Logout service function
const logout = async () => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}v2/auth/business/portal/logout`,
      {},
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "Content-Type": "application/json",
        },
      },
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("API Error:", error.response.status, error.response.data);
      throw new Error(`API Error: ${error.response.status}`);
    } else {
      console.error("Network Error:", error.message);
      throw new Error(`Network Error: ${error.message}`);
    }
  }
};

export default logout;
