import React from "react";
import "./pass.css";
import BrandLogoPlaceholder from "../../assets/placeholders/logo-placholder.jpg";
import Location from "../Location/location";

function Pass({
  brandName,
  location,
  customerName,
  heroImage,
  customerId,
  memberSince,
  qrCodeImage,
  qrCodeNumber,
  brandLogo,
}) {
  return (
    <div className="pass">
      <div className="pass__head">
        <a className="pass__logo-preview">
          {/*<a>... </a> can be changed to <div>...</div>*/}
          <img
            className="pass__logo"
            src={brandLogo ? brandLogo : BrandLogoPlaceholder}
            alt=""
          />
        </a>
        <div className="pass__summary">
          <div className="pass__heading">{brandName}</div>
          <div className="pass__location">
            <Location>{location}</Location>
          </div>
        </div>
      </div>
      <div className="pass__hero">
        <img
          className="pass__image"
          src={heroImage}
          width="320"
          height="123"
          alt=""
        />
      </div>
      <div className="pass__body">
        <div className="pass__customer">
          <div className="pass__customer-label">Customer</div>
          <div className="pass__customer-value">{customerName}</div>
        </div>
        <div className="pass__data">
          <div className="pass__param">
            <div className="pass__param-label">ID</div>
            <div className="pass__param-value">{customerId}</div>
          </div>
          <div className="pass__param">
            <div className="pass__param-label">Member since</div>
            <div className="pass__param-value">{memberSince}</div>
          </div>
        </div>
        <div className="pass__qr">
          <img
            className="pass__qr-image"
            src={qrCodeImage}
            width="118"
            height="118"
            alt=""
          />
          <div className="pass__qr-number">{qrCodeNumber}</div>
        </div>
      </div>
    </div>
  );
}

export default Pass;
