import React from "react";
import "./a5_voucher.css";
import PoweredByMeed from "../PoweredByMeed/powered_by_meed";

function A5Voucher({ data }) {
  return (
    <div className="a5-voucher">
      <div
        className="a5-voucher__body"
        style={{ backgroundColor: "rgb(0 112 66)" }}
      >
        <div className="a5-voucher__inner">
          <div className="a5-voucher__panel">
            <div className="a5-voucher__logo">
              <img
                src={`${process.env.REACT_APP_CDN_URL}${data.businessLogo}.jpg`}
                alt="Business Logo"
              />
            </div>
            <div className="a5-voucher__slogan">{data.offer}</div>
            <div className="a5-voucher__join">
              <img
                src={`${process.env.REACT_APP_QR_CODE_URL}${data.programUuid}`}
                alt="Business QR"
              />
            </div>
          </div>
        </div>
        <div className="a5-voucher__footer">
          <PoweredByMeed />
        </div>
      </div>
    </div>
  );
}

export default A5Voucher;
