// @ts-ignore
import { useNavigate } from "react-router-dom";
// @ts-ignore
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";
import axiosInstance from "../services/axiosIntercept";
import { DeviceUUIDComponent } from "../device_id";

const useSetAccountChange = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const navigate = useNavigate();
  const userUuid = useSelector(selectors.getUuid);
  const otp = useSelector(selectors.getOtp);
  let deviceUUID = DeviceUUIDComponent();

  // @ts-ignore
  const setAccountChange = async (/** @type {any} */ accountUuid) => {
    try {
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}v2/account/change`,
          {
            userUuid: userUuid,
            accountUuid: accountUuid,
            deviceId: deviceUUID,
            otp: otp,
          },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
            },
          },
        )
        .then((res) => {});
    } catch (error) {
      throw error;
    }
  };

  return setAccountChange;
};

export default useSetAccountChange;
