import React from "react";
import "./a6_voucher.css";
import PoweredByMeed from "../PoweredByMeed/powered_by_meed";

function A6Voucher({ data }) {
  return (
    <div className="a6-voucher">
      <div
        className="a6-voucher__body"
        style={{ backgroundColor: "rgb(0 112 66)" }}
      >
        <div className="a6-voucher__header">
          <div className="a6-voucher__logo">
            <img
              src={`${process.env.REACT_APP_CDN_URL}${data.businessLogo}.jpg`}
              alt="Business Logo"
            />
          </div>
          <div className="a6-voucher__brand">{data.businessName}</div>
        </div>
        <div className="a6-voucher__panel">
          <div className="a6-voucher__join">
            <img
              src={`${process.env.REACT_APP_QR_CODE_URL}${data.programUuid}`}
              alt="Business QR"
            />
          </div>
          <div className="a6-voucher__scan-me">Scan me</div>
        </div>
        <div className="a6-voucher__footer">
          <PoweredByMeed />
        </div>
      </div>
    </div>
  );
}

export default A6Voucher;
