import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as actions from "../../actions/actions";
import * as selectors from "../../selectors/selectors";
import Info_Panel from "../InfoPanel/info_panel";
import Label from "../Label/label";
import Helper from "../Helper/helper";
import Explainer from "../../Components/Explainer/explainer";
import AppInput from "../AppInput/app_input";

// TODO: Make this component pure
function Discount_Voucher() {
  const dispatch = useDispatch();

  const productServiceDescription = useSelector(selectors.getGiveaway);
  const stored_discount_value = useSelector(selectors.getDiscountVoucher);

  const [value, setValue] = useState({
    ref: productServiceDescription ? productServiceDescription : null,
    discount_value: stored_discount_value ? stored_discount_value : null,
    address: null,
  }); //* To set all values in this.

  let options_dropdown = [];
  for (let i = 0; i < 10; i++) {
    const discountValue = +((i + 1) * 0.05).toFixed(2);
    const description = discountValue * 100 + "%";
    const option = { value: discountValue, description: description };
    options_dropdown.push(option);
  }

  return (
    <div className="form">
      <div className="form-content">
        <div className="voucher__widget">
          <Label>
            Product or service
            <Explainer size="20">
              Enter the name of the product or service
              <br />
              you are applying the voucher to.
              <br />
              If everything, type 'everything'!
            </Explainer>
          </Label>
          <AppInput
            maxLength={20}
            placeholder="Example: Blue cheese or new haircut"
            value={value.ref}
            onChange={(e) => {
              setValue({ ...value, ref: e.target.value });
              dispatch(actions.setGiveaway(e.target.value));
            }}
          />
          <Helper>Maximum 20 characters</Helper>
        </div>
        <div className="voucher__widget">
          <Label>
            Discount value
            <Explainer size="20">
              Choose the value of your voucher
              <br />
              (for cash and percentage discounts)
            </Explainer>
          </Label>
          <AppInput
            tag="select"
            value={value.discount_value}
            onChange={(e) => {
              setValue({ ...value, discount_value: e.target.value });
              dispatch(actions.setDiscountVoucher(Number(e.target.value)));
            }}
            selectPlaceholder="Select value"
            selectOptions={options_dropdown}
          />
        </div>
        <Info_Panel>
          This percentage will be discounted from the product or service price
        </Info_Panel>

        {/* <div id="sub-title">Event address</div>
              <textarea
              placeholder="Add text"
                type="text"
                value={value.address}
                onChange={(e) => {
                  setValue({...value,address:e.target.value});
                  dispatch({ type: "SET_EVENT_ADDRESS", payload: e.target.value});
                }}
                className="event"
              /> */}
      </div>
    </div>
  );
}

export default Discount_Voucher;
