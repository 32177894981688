import ReactJoyride, { STATUS } from "react-joyride";
import { useEffect, useState } from "react";

/**
 *
 * @param {object} props
 * @param {import("react-joyride").Step[]} props.steps
 * @param {string} props.id
 * @returns {JSX.Element}
 */
function GuidedTour({ steps, id }) {
  const localStorageKey = `guided_tour_${id}_shown`;
  const [open, setOpen] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      localStorage.setItem(localStorageKey, "1");
      setOpen(false);
    }
  };

  useEffect(() => {
    // Only open when the this specific guided tour has not been shown before (`localStorageKey` not found in localStorage)
    if (!localStorage.getItem(localStorageKey)) {
      setOpen(true);
    }
  }, [localStorageKey]);

  return (
    open && (
      <ReactJoyride
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton
        run={true}
        showProgress
        showSkipButton
        steps={steps.map((step) => ({ ...step, disableBeacon: true }))}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: "#e06932",
          },
        }}
      />
    )
  );
}

export default GuidedTour;
