import {
  AccountAndUserRole,
  LimitCheckAlertStatus,
  OverviewData,
  Voucher,
} from "../types";
import * as types from "./actionTypes";
import * as account from "./accountActionTypes";
/**
 *
 * @param {number} discountVoucher discount in decimal (20% = 0.2)
 * @returns {import("redux").Action}
 */
export const setDiscountVoucher = (discountVoucher: any) => ({
  type: types.SET_DISCOUNT_VOUCHER,
  payload: discountVoucher,
});

export const setCurrency = (currency: any) => ({
  type: types.SET_CURRENCY,
  payload: currency,
});

export const resetVoucherCampaign = () => ({
  type: types.RESET,
});

export const setCashVoucher = (value: any) => ({
  type: types.SET_CASH_VOUCHER,
  payload: value,
});

export const setGiveaway = (productServiceDescription: any) => ({
  type: types.SET_GIVEAWAY,
  payload: productServiceDescription,
});

export const setSpecialAddress = (specialAddress: any) => ({
  type: types.SET_SPECIAL_ADDRESS,
  payload: specialAddress,
});

export const setEventPass = (eventCode: any) => ({
  type: types.SET_EVENT_PASS,
  payload: eventCode,
});

export const setEventAddress = (eventAddress: any) => ({
  type: types.SET_EVENT_ADDRESS,
  payload: eventAddress,
});

export const setName = (name: string) => ({
  type: types.SET_NAME,
  payload: name,
});

export const setCurrentSubscription = (currentSubscription: any) => ({
  type: types.SET_CURRENT_SUBSCRIPTION,
  payload: currentSubscription,
});

export const setMerchantUserRole = (merchantUserRole: string | undefined) => ({
  type: types.SET_MERCHANT_USER_ROLE,
  payload: merchantUserRole,
});

export const setSuperAdmin = (superAdmin: boolean) => ({
  type: types.SET_SUPER_ADMIN,
  payload: superAdmin,
});

export const setEmail = (email: string) => ({
  type: types.SET_EMAIL,
  payload: email,
});

export const setId = (id: number) => ({
  type: types.SET_ID,
  payload: id,
});

export const setAccountUuidToLogIn = (accountUuid: string) => ({
  type: types.SET_ACCOUNT_UUID_TO_LOG_IN,
  payload: accountUuid,
});

export const setUuid = (uuid: string) => ({
  type: types.SET_UUID,
  payload: uuid,
});

export const setProfileImage = (pic: string | null) => ({
  type: types.SET_PROFILE_IMAGE_ID,
  payload: pic,
});

export const setLimitcheck = (limitcheck: LimitCheckAlertStatus) => ({
  type: types.SET_LIMIT_CHECK,
  payload: limitcheck,
});

export const setCheckRestart = (checkRestart: any) => ({
  type: types.SET_CHECK_RESTART,
  payload: checkRestart,
});

export const setAnalytics = (analytics: any) => ({
  type: types.SET_ANALYTICS,
  payload: analytics,
});

export const setOtp = (otp: string) => ({
  type: types.SET_OTP,
  payload: otp,
});

export const setIsGoPremiumPopupShown = (payload: boolean) => ({
  type: types.SET_IS_GO_PREMIUM_POPUP_SHOWN,
  payload,
});

export const setOverviewData = (overviewData?: OverviewData) => ({
  type: types.SET_OVERVIEW_DATA,
  payload: overviewData,
});

export const setIsLoadingOverviewData = (payload: boolean) => ({
  type: types.SET_IS_LOADING_OVERVIEW_DATA,
  payload,
});

export const setColour = (colour: any) => ({
  type: types.SET_COLOUR,
  payload: colour,
});

export const setProgram = (program: any) => ({
  type: types.SET_PROGRAM,
  payload: program,
});

export const setIsLoadingProgram = (payload: boolean) => ({
  type: types.SET_IS_LOADING_PROGRAM,
  payload,
});

export const setVouchers = (vouchers: Voucher[] | null) => ({
  type: types.SET_VOUCHERS,
  payload: vouchers,
});

export const setVoucher = (voucherUuid: string, data: Partial<Voucher>) => ({
  type: types.SET_VOUCHER,
  payload: {
    voucherUuid,
    data,
  },
});

export const setIsLoadingVouchers = (isLoading: boolean) => ({
  type: types.SET_IS_LOADING_VOUCHERS,
  payload: isLoading,
});

export const setIndustries = (industries: any) => ({
  type: types.SET_INDUSTRIES,
  payload: industries,
});

export const setSelectedColor = (selectedColor: any) => ({
  type: types.SET_SELECTED_COLOR,
  payload: selectedColor,
});

export const setBusinessImageName = (businessImageName: any) => ({
  type: types.SET_BUSINESS_IMAGE_NAME,
  payload: businessImageName,
});
export const setSelectedProgramName = (selectedProgramName: string) => ({
  type: types.SET_SELECTED_PROGRAM_NAME,
  payload: selectedProgramName,
});
export const setSelectedProgramLocation = (
  selectedProgramLocation: string,
) => ({
  type: types.SET_SELECTED_PROGRAM_LOCATION,
  payload: selectedProgramLocation,
});

export const setSelectedLp = (selectedLp?: number) => ({
  type: types.SET_SELECTED_LP,
  payload: selectedLp,
});
export const setSelectedLpUuid = (selectedLp: any) => ({
  type: types.SET_SELECTED_LP_UUID,
  payload: selectedLp,
});

export const setImpersonatingMerchantId = (
  impersonatingMerchantId?: string,
) => ({
  type: types.SET_IMPERSONATING_MERCHANT_ID,
  payload: impersonatingMerchantId,
});

export const logout = () => ({
  type: types.LOGOUT,
});

export const setAuthIsLoading = (isLoading?: boolean) => ({
  type: types.SET_AUTH_IS_LOADING,
  payload: Boolean(isLoading),
});

export const setAuthError = (error: any) => ({
  type: types.SET_AUTH_ERROR,
  payload: error,
});

export const setIsAuthenticated = () => ({
  type: types.SET_IS_AUTHENTICATED,
  payload: true,
});

// Account
export const setAccounts = (payload: AccountAndUserRole[]) => ({
  type: types.SET_ACCOUNTS,
  payload,
});
export const setAccount = (payload: AccountAndUserRole) => ({
  type: account.SET_ACCOUNT,
  payload,
});
export const setAccountName = (payload: string) => ({
  type: account.SET_ACCOUNT_NAME,
  payload,
});
export const addAccount = (payload: AccountAndUserRole) => ({
  type: types.ADD_ACCOUNT,
  payload,
});
