import { AnalyticsData, LimitCheckAlertStatus, RootState } from "../types";

export const getVouchers = (state: RootState) => state.common.vouchers;
export const getIsLoadingVouchers = (state: RootState) =>
  state.common.isLoadingVouchers;
export const getDiscountVoucher = (state: RootState) =>
  state.voucherCampaign.discountVoucher;
export const getCashVoucher = (state: RootState) =>
  state.voucherCampaign.cashVoucher;
export const getGiveaway = (state: RootState) => state.voucherCampaign.giveaway;
export const getSpecialAddress = (state: RootState) =>
  state.voucherCampaign.specialAddress;
export const getCurrency = (state: RootState) => state.voucherCampaign.currency;
export const getEventPass = (state: RootState) =>
  state.voucherCampaign.eventPass;
export const getEventAddress = (state: RootState) =>
  state.voucherCampaign.eventAddress;
export const getName = (state: RootState) => state.user.name;
export const getCurrentSubscription = (state: RootState): number =>
  state.user.currentSubscription;
export const getMerchantUserRole = (state: RootState) =>
  state.user.merchantUserRole;
export const getSuperAdmin = (state: RootState) => state.user.superAdmin;
export const getEmail = (state: RootState) => state.user.email;
export const getId = (state: RootState) => state.user.id;
export const getActualUuid = (state: RootState) => state.user.uuid;
export const getProfileImageId = (state: RootState) =>
  state.user.profileImageId;
export const getUuid = (state: RootState): string | undefined =>
  state.user.impersonatingMerchantId || state.user.uuid;
export const getLimitcheck = (
  state: RootState,
): LimitCheckAlertStatus | undefined => state.user.limitcheck;
export const getAnalytics = (state: RootState): AnalyticsData =>
  state.user.analytics;
export const getOtp = (state: RootState) => state.user.otp;
export const getAccountUuidToLogIn = (state: RootState) =>
  state.user.accountUuidToLogIn;
export const getCheckRestart = (state: RootState) => state.user.checkRestart;
export const getSelectedLp = (state: RootState) => state.common.selected_lp;
export const getSelectedLpUuid = (state: RootState) =>
  state.common.selected_lp_uuid;
export const getImpersonatingMerchantId = (state: RootState) =>
  state.user.impersonatingMerchantId;
export const getSelectedProgramName = (state: RootState) =>
  state.common.selected_program_name;
export const getSelectedProgramLocation = (state: RootState) =>
  state.common.selected_program_location;
export const getBusinessImageName = (state: RootState) =>
  state.common.business_image_name;
export const getProgram = (state: RootState) => state.common.program;
export const getIsLoadingPrograms = (state: RootState) =>
  state.common.isLoadingPrograms;
export const getHasAnyProgram = (state: RootState) =>
  state.common.program ? state.common.program.length > 0 : false;
export const getIsGoPremiumPopupShown = (state: RootState) =>
  state.common.isGoPremiumPopupShown;
export const getIsLoadingOverviewData = (state: RootState) =>
  state.common.isLoadingOverviewData;
export const getOverviewData = (state: RootState) => state.common.overview_data;
export const getIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;
export const getAuthIsLoading = (state: RootState) => state.auth.isLoading;
export const getAuthError = (state: RootState) => state.auth.error;
export const getIsLoggedOut = (state: RootState) => state.auth.isLoggedOut;
export const getIndustries = (state: RootState) => state.common.industries;
export const getBusinessBrandColor = (state: RootState) =>
  state.common.selected_color;

// Account
export const getAccounts = (state: RootState) => state.common.accounts;
export const getAccountId = (state: RootState) => state.account.id;
export const getAccountUuid = (state: RootState): string | undefined =>
  state.account.uuid;
export const getAccountName = (state: RootState) => state.account.name;
export const getAccountSubscription = (state: RootState) =>
  state.account.subscription;
export const getUserAccountRole = (state: RootState) =>
  state.account.userAccountRole;
