import React from "react";
import "./label.css";

/**
 *
 * @param {object} props
 * @param {boolean} [props.smaller]
 * @param {import("react").ReactNode} props.children
 * @returns {JSX.Element}
 */
function Label({ children, smaller }) {
  return (
    <span className={`label ${smaller ? " label--smaller" : ""}`}>
      {children}
    </span>
  );
}

export default Label;
