import axiosInstance from "../services/axiosIntercept";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";

const useResendInvite = () => {
  const uuid = useSelector(selectors.getUuid);
  const resendInvite = async (name, email, accountUuid) => {
    try {
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}v1/business/resendInvitation`,
          {
            name,
            email,
            accountUuid,
          },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
            },
          },
        )
        .then((res) => {
          console.log("resend invite", res.data);
        });
    } catch (error) {
      console.error("Error while resend invite:", error);
      throw error;
    }
  };

  return resendInvite;
};

export default useResendInvite;
