import React from "react";
import "./outer_close.css";
import { ReactComponent as CrossIcon } from "../../assets/symbols/cross.svg";

/**
 *
 * @param {object} props
 * @param {import("react").MouseEventHandler<HTMLButtonElement>} props.onClick
 * @param {boolean} [props.largerSize]
 * @returns {JSX.Element}
 */
function OuterClose({ onClick, largerSize }) {
  return (
    <button
      className={`outer-close${largerSize ? " outer-close--larger" : ""}`}
      type="button"
      onClick={onClick}
    >
      <CrossIcon className="outer-close__cross" />
    </button>
  );
}

export default OuterClose;
