import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  error: null,
  isLoggedOut: true,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH_IS_LOADING:
      return { ...state, isLoading: true, error: null };
    case actionTypes.SET_IS_AUTHENTICATED:
      console.log("SET_AUTHENTICATED");
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        error: null,
        isLoggedOut: false,
      };
    case actionTypes.SET_AUTH_ERROR:
      console.log("SET_AUTH_ERROR", action.payload);
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: action.payload,
        isLoggedOut: false,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        error: null,
        isLoggedOut: true,
      };
    default:
      return state;
  }
};

export default authReducer;
