// actions/programActions.js
import { fetchProgramsByAccount } from "../services/programService";
import * as actions from "../actions/actions";
import { CREATE_LOYALTY_PAGE } from "../utils/constants";

export const getProgramsAction =
  (
    /** @type {string} */ accountUuid,
    /** @type {string|undefined} */ selectedProgramId,
    /** @type {import("react-router-dom").NavigateFunction} */ navigate,
  ) =>
  async (dispatch) => {
    if (!accountUuid) {
      return;
    }

    dispatch(actions.setIsLoadingProgram(true));

    try {
      const programs = await fetchProgramsByAccount(accountUuid);
      dispatch(actions.setProgram(programs));

      console.log(programs);

      if (!selectedProgramId) {
        if (programs.length > 0) {
          dispatch(actions.setSelectedLp(programs[programs.length - 1].id));
          dispatch(
            actions.setSelectedProgramName(programs[programs.length - 1].name),
          );
          dispatch(
            actions.setSelectedLpUuid(programs[programs.length - 1].uuid),
          );
          console.log("Selected program set in redux store:", programs[0].id);
        } else {
          navigate(`/dashboard/${CREATE_LOYALTY_PAGE}`);
        }
      }
    } catch (error) {
      console.error("Error fetching programs:", error);
      // Handle error appropriately
    } finally {
      dispatch(actions.setIsLoadingProgram(false));
    }
  };
