// actions/industryActions.js
import { fetchIndustries } from "../services/industryService";

export const getIndustries = () => async (dispatch) => {
  try {
    const industries = await fetchIndustries();
    dispatch({ type: "SET_INDUSTRIES", payload: industries });
  } catch (error) {
    console.error("Error fetching industries:", error);
    // Handle error appropriately
  }
};
