import axios, { AxiosError, AxiosResponse } from "axios";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error: AxiosError) => {
    // Handle request errors here
    return Promise.reject(error);
  },
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Modify the response data here (e.g., parse, transform)
    return response;
  },
  (error: AxiosError) => {
    // Handle response errors here
    if (error.response && error.response.status === 401) {
      // Redirect to '/' on a 401 response
      window.location.href = "/"; // Change to the appropriate navigation method in your React app
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
