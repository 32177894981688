import axiosInstance from "./axiosIntercept";

/**
 *
 * @param {Pick<import("../types").User, "name" | "email" | "role"> & { merchantUuid: string }} user
 * @returns {Promise<string|undefined>}
 */
export async function createNewUser(user) {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}v2/business/user/add`,
      user,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error creating a new user:",
      error.response || error.message,
    );
    throw error;
  }
}

/**
 * @param {string} accountUuid
 * @returns {Promise<import("../types").User[]>}
 */
export async function getUsers(accountUuid) {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}v2/business/users/${accountUuid}`,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error loading user list:", error.response || error.message);
    throw error;
  }
}

/**
 * @param {number} id
 * @param {number} accountId
 * @returns {Promise<void>}
 */
export async function deleteUser(id, accountId) {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}v1/business/delete/user`,
      {
        id: id,
        accountId,
        active: false,
      },
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error.response || error.message);
    throw error;
  }
}

/**
 * @param {number} id
 * @param {number} merchantUserRoleId
 * @param {number} accountId
 * @returns {Promise<void>}
 */
export async function changeUserRole(id, merchantUserRoleId, accountId) {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}v1/business/update/user/role`,
      {
        userId: id,
        newRole: merchantUserRoleId,
        accountId: accountId,
      },
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error.response || error.message);
    throw error;
  }
}
