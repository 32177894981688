import React from "react";
import "./show_all_programs.css";
import { ReactComponent as ChevronDownMediumIcon } from "../../assets/symbols/chevron-down-medium.svg";

function ShowAllPrograms({ children, onClick, horizontal }) {
  return (
    <button
      className={`show-all-programs${
        horizontal ? " show-all-programs--horizontal" : ""
      }`}
      type="button"
      onClick={onClick}
    >
      <span className="show-all-programs__label">{children}</span>
      <ChevronDownMediumIcon
        className="show-all-programs__icon"
        width="16"
        height="11"
      />
    </button>
  );
}

export default ShowAllPrograms;
