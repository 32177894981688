import axiosInstance from "./axiosIntercept";

/**
 * @param {ArrayBuffer} buffer
 * @returns {string}
 */
function hex(buffer) {
  var hexCodes = [];
  var view = new DataView(buffer);
  for (var i = 0; i < view.byteLength; i += 4) {
    var value = view.getUint32(i);
    var stringValue = value.toString(16);
    var padding = "00000000";
    var paddedValue = (padding + stringValue).slice(-padding.length);
    hexCodes.push(paddedValue);
  }
  return hexCodes.join("");
}

export const dataUrlToArrayBuffer = (dataUrl) => {
  const base64 = dataUrl.split(",")[1];
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
};

/**
 * @param {ArrayBuffer} buffer
 * @returns {Promise<string>} sha
 */
export const uploadFile = async (buffer) => {
  const sha = hex(await crypto.subtle.digest("SHA-256", buffer));

  const formData = new FormData();
  formData.append("file", new Blob([buffer], { type: "image/jpeg" }));

  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}v1/s3/upload?key=${sha}.jpg`,
      formData,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
          "content-type": "multipart/form-data",
        },
      },
    );
    console.log("Image uploaded successfully:", response.data);

    return sha;
  } catch (error) {
    console.error("Error uploading image:", error);
  }
};
