import React from "react";
import "./progress.css";

function Progress({ value, color }) {
  return (
    <div className="progress">
      <div
        className="progress__value"
        style={{ width: value + "%", backgroundColor: color }}
      ></div>
    </div>
  );
}

export default Progress;
