import React from "react";
import "./choice.css";

/**
 *
 * @param {object} props
 * @param {"radio"} props.type
 * @param {string} props.name
 * @param {boolean} props.checked
 * @param {import("react").ReactNode} props.children
 * @param {import("react").ChangeEventHandler<HTMLInputElement>} [props.onChange]
 * @returns {JSX.Element}
 */
function Choice({ children, type, onChange, checked, ...restProps }) {
  return (
    <label className="choice">
      <input
        className="choice__widget"
        type={type}
        onChange={onChange}
        checked={checked}
        {...restProps}
      />
      <span className="choice__label">{children}</span>
      <i className="choice__icon" role="presentation"></i>
    </label>
  );
}

export default Choice;
