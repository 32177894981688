import React from "react";
import "./location.css";
import { ReactComponent as GeoIcon } from "./../../assets/symbols/geo.svg";

/**
 *
 * @param {object} props
 * @param {string} [props.variant]
 * @param {import("react").ReactNode} props.children
 * @returns {import("react").ReactNode}
 */
function Location({ variant, children }) {
  return (
    <div className={`location${variant ? " location--" + variant : ""}`}>
      <GeoIcon className="location__icon" />
      {children}
    </div>
  );
}

export default Location;
