import React, { FormEventHandler, useState } from "react";
import styles from "./addOrganization.module.css";
import { notification } from "antd";
import Popup from "../Popup/popup";
import { useSelector } from "react-redux";
import BackIcon from "../../assets/icons/back.svg";
import { getAccounts } from "../../selectors/selectors";
import Organization_Name_Page from "../../Pages/OrganizationName/organization_name";
import useAddOrganization from "../../Hooks/addOrganization";
import { useNavigate } from "react-router-dom";

type Props = { onCancel: () => void };
const AddOrganization: React.FC<Props> = ({ onCancel }) => {
  const accounts = useSelector(getAccounts);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addOrganization = useAddOrganization();
  const [isAddOrganizationModalOpen, setIsAddOrganizationModalOpen] =
    useState(false);
  const navigate = useNavigate();

  console.log(accounts);

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    notification.info({
      message: "New Organization added..",
    });

    navigate("/dashboard");
  };

  return (
    <Popup disableOverlayClick onCloseClick={onCancel}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset disabled={isSubmitting}>
          <div className={styles.title}>Personal Information</div>

          <table className={styles.tablecontainer}>
            <thead>
              <tr className={styles.tableheader}>
                <th>
                  <img
                    src={BackIcon}
                    alt="back"
                    onClick={onCancel}
                    style={{ cursor: "pointer", height: "16px" }}
                  />
                  Organization Name
                </th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <hr></hr>
            <tbody>
              {accounts?.map((item, index) => (
                <tr key={index} className={styles.tablerow}>
                  <td>{item.account?.name ? item.account.name : "N/A"}</td>
                  <td>{item.userRole.name}</td>
                  <td>
                    <button type="button" className="button" disabled>
                      Request to delete organization
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <hr></hr>

          <div className={styles.buttons}>
            <button
              type="button"
              className="button"
              onClick={() => {
                setIsAddOrganizationModalOpen(true);
              }}
            >
              Create new Organization
            </button>
          </div>
        </fieldset>
        {isAddOrganizationModalOpen && (
          <Organization_Name_Page
            onSave={async (name) => {
              try {
                // Handle the saving here, not inside the Organization_Name_Page component.
                await addOrganization(name);

                notification.success({
                  message: `Organization ${name} created successfully.`,
                  description: "",
                });
              } catch {
                notification.error({
                  message: `Failed to create the organization. Please try again.`,
                  description: "",
                });
              } finally {
                setIsAddOrganizationModalOpen(false);
              }
            }}
          />
        )}
      </form>
    </Popup>
  );
};

export default AddOrganization;
