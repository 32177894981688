import React from "react";
import "./info_panel.css";
import { ReactComponent as InfoOutlineIcon } from "../../assets/symbols/info-outline.svg";

function Info_Panel({ children }) {
  return (
    <div className="info-panel">
      <InfoOutlineIcon className="info-panel__icon" />
      <div className="info-panel__label">{children}</div>
    </div>
  );
}

export default Info_Panel;
