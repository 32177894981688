import React from "react";
import "./tooltip.css";

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {"top"|"right"|"bottom"|"left"|undefined} [props.position]
 * @param {boolean} [props.normalWrap]
 * @returns {JSX.Element}
 */
function Tooltip({ children, position, normalWrap, ...restProps }) {
  const positionString = position ? ` tooltip--${position}` : " tooltip--top";
  const normalWrapString = normalWrap ? " tooltip--normal-wrap" : "";
  return (
    <span
      className={`tooltip${positionString}${normalWrapString}`}
      {...restProps}
    >
      <i className="tooltip__peak"></i>
      <span className="tooltip__body">{children}</span>
    </span>
  );
}

export default Tooltip;
