// Desc: Helper functions
import { notification } from "antd";
import { VoucherType } from "./constants";

notification.config({
  top: 100, // Adjust the top offset as needed
});

export function warningWithTimeout(options) {
  notification.warning({
    ...options,
    duration: 4,
    placement: "top",
  });
}

function hex(buffer) {
  var hexCodes = [];
  var view = new DataView(buffer);
  for (var i = 0; i < view.byteLength; i += 4) {
    var value = view.getUint32(i);
    var stringValue = value.toString(16);
    var padding = "00000000";
    var paddedValue = (padding + stringValue).slice(-padding.length);
    hexCodes.push(paddedValue);
  }
  return hexCodes.join("");
}

export function getVoucherTypeDescription(typeCode) {
  switch (typeCode) {
    case VoucherType.CASH:
      return "Cash";
    case VoucherType.DISCOUNT:
      return "Discount";
    case VoucherType.GIVEAWAY:
      return "Giveaway";
    case VoucherType.EVENT_PASS:
      return "Event Pass";
    default:
      return "Unknown";
  }
}

export function formatLocation(city, state, country) {
  let parts = [city, state, country];
  let filteredParts = parts.filter(
    (part) => part != null && part.trim() !== "",
  );
  return filteredParts.join(", ");
}

/**
 * @param {string|undefined} address
 * @returns {string}
 */
export function formatAddress(address) {
  return address?.replace(/000000/g, "") || "";
}

export function extractCurrencyCode(valueDescription) {
  const words = valueDescription.split(" ");
  let currencyCode = "";

  for (let i = 0; i < words.length; i++) {
    if (!isNaN(words[i])) {
      currencyCode = words[i + 1];
      break;
    }
  }

  return currencyCode || "HKD";
}

/**
 *
 * @param {string} valueDescription
 * @returns {number|undefined}
 */
export function extractDiscountVoucherFromValueDescription(valueDescription) {
  // Use a regular expression to find the first occurrence of a number followed by a percent sign
  const match = valueDescription.match(/(\d+)%/);

  // Convert the matched number string to an integer
  const percentage = match ? parseInt(match[1], 10) / 100 : undefined;

  return percentage;
}
export function getScrollbarWidth() {
  const outer = document.createElement("div");
  const inner = document.createElement("div");

  outer.classList.add("scrollbar-width-check");
  inner.classList.add("scrollbar-width-check__inner");

  document.body.appendChild(outer);
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}
