import React, { useState } from "react";
import "./pagination.css";
import AppButton from "../AppButton/app_button";

/**
 * @param {object} props
 * @param {number} props.currentPage
 * @param {number} props.itemsPerPage
 * @param {number} props.totalItems
 * @param {(page: number) => Promise<void>|void} props.onChangePage
 * @returns {import("react").ReactNode}
 */
function Pagination({ currentPage, itemsPerPage, onChangePage, totalItems }) {
  const totalNumberOfPages = Math.ceil(totalItems / itemsPerPage);
  const [isLoading, setIsLoading] = useState(false);
  const changePage = async (i) => {
    try {
      setIsLoading(true);
      await onChangePage(i);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pagination">
      {currentPage > 1 && (
        <AppButton
          variant="secondary"
          almostASquare
          disabled={isLoading}
          onClick={() => {
            changePage(currentPage - 1);
          }}
        >
          ←
        </AppButton>
      )}
      {currentPage > 1 && (
        <AppButton
          variant="secondary"
          almostASquare
          disabled={isLoading}
          onClick={() => {
            changePage(1);
          }}
        >
          1
        </AppButton>
      )}
      {currentPage > 6 && <div className="pagination__separator">&hellip;</div>}
      {Array(4)
        .fill(1)
        .map(
          (_, i) =>
            currentPage + (i - 4) > 1 && (
              <AppButton
                variant="secondary"
                almostASquare
                disabled={isLoading}
                onClick={() => {
                  changePage(currentPage + (i - 4));
                }}
              >
                {currentPage + (i - 4)}
              </AppButton>
            ),
        )}
      <AppButton almostASquare disabled={isLoading}>
        {currentPage}
      </AppButton>
      {Array(4)
        .fill(1)
        .map(
          (_, i) =>
            currentPage + i + 1 < totalNumberOfPages && (
              <AppButton
                variant="secondary"
                almostASquare
                disabled={isLoading}
                onClick={() => {
                  changePage(currentPage + i + 1);
                }}
              >
                {currentPage + i + 1}
              </AppButton>
            ),
        )}
      {currentPage < totalNumberOfPages - 5 && (
        <div className="pagination__separator">&hellip;</div>
      )}
      {currentPage < totalNumberOfPages && (
        <AppButton
          variant="secondary"
          almostASquare
          onClick={() => {
            changePage(totalNumberOfPages);
          }}
        >
          {totalNumberOfPages}
        </AppButton>
      )}
      {currentPage < totalNumberOfPages && (
        <AppButton
          variant="secondary"
          almostASquare
          disabled={isLoading}
          onClick={() => {
            changePage(currentPage + 1);
          }}
        >
          →
        </AppButton>
      )}
    </div>
  );
}

export default Pagination;
