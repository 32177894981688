import React, { useEffect, useState } from "react";
import styles from "./create-user.module.css";
import { notification } from "antd";
import Label from "../../Components/Label/label";
import AppInput from "../../Components/AppInput/app_input";
import Popup from "../../Components/Popup/popup";
import { createNewUser } from "../../services/userManagementService";
import { useSelector } from "react-redux";
import {
  getAccountUuid,
  getLimitcheck,
  getUuid,
} from "../../selectors/selectors";
import { useShowTierLimitReachedWarning } from "../../utils/utils";

/**
 *
 * @param {object} props
 * @param {() => void} props.onCancel
 * @param {() => void} props.onSuccess
 * @returns {JSX.Element}
 */
function CreateUser({ onCancel, onSuccess }) {
  const accountUuid = useSelector(getAccountUuid);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("USER");
  const checkLimit = useSelector(getLimitcheck);
  const showTierUserLimitReachedWarning =
    useShowTierLimitReachedWarning("user");

  console.log(checkLimit.userLimitReached);

  useEffect(() => {
    const emailRegex = /\S+@\S+\.\S+/;

    // Check if the inputEmail matches the emailRegex
    setIsFormValid(emailRegex.test(email) && name !== "");
  }, [email, name]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    // Check if the user limit has been reached
    if (checkLimit.userLimitReached) {
      showTierUserLimitReachedWarning();
    } else {
      notification.info({
        message: "Creating new user",
        description: `Please wait...`,
      });

      try {
        await createNewUser({
          name,
          email,
          role,
          accountUuid,
        });

        onSuccess();
      } catch (err) {
        console.error("Error while creating the new user", err);
        notification.warning({
          message:
            "Error occured while creating the new user. Please try again or contact customer service",
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Popup disableOverlayClick onCloseClick={onCancel}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset disabled={isSubmitting}>
          <div className={styles.title}>Invite Member</div>

          <div className={styles.field}>
            <Label>Name</Label>
            <AppInput
              name="name"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className={styles.field}>
            <Label>Email address</Label>
            <AppInput
              name="email"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className={styles["field__helper-text"]}>
              Enter email manually or copy paste it
            </div>
          </div>

          <div className={styles.field}>
            <Label>Assign role</Label>
            <AppInput
              tag="select"
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
              }}
              selectOptions={[
                { value: "USER", description: "User" },
                { value: "ADMIN", description: "Admin" },
              ]}
              selectPlaceholder="Select role"
            />
            <div className={styles["field__helper-text"]}>
              You can change the member's organisation role at any time
            </div>
          </div>

          <div className={styles.buttons}>
            <button
              type="button"
              className="button button--secondary button--full-width"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </button>
            <button type="submit" className="button" disabled={!isFormValid}>
              Next
            </button>
          </div>
        </fieldset>
      </form>
    </Popup>
  );
}

export default CreateUser;
