import React, { useEffect, useState } from "react";
import "./program_bar.css";
import Location from "../Location/location";
import useGetLocations from "../../Hooks/getLocation";

/**
 *
 * @param {object} props
 * @param {boolean} [props.selected]
 * @param {string} [props.logo]
 * @param {string} props.name
 * @param {string} props.location
 * @param {string} [props.category]
 * @param {string} props.programUuid
 * @param {() => void} [props.onClick]
 * @returns {import("react").ReactNode}
 */
function ProgramBar({
  selected,
  logo,
  name,
  location: locationFromParent,
  category,
  programUuid,
  onClick,
}) {
  const getLocation = useGetLocations(programUuid);
  const [location, setLocation] = useState(locationFromParent);

  useEffect(() => {
    getLocation().then((x) => {
      const loc = x?.[0]?.address;
      if (loc) {
        setLocation(loc || "");
      }
    });
  });

  return (
    <button
      className={`program-bar${selected ? " program-bar--current" : ""}`}
      type="button"
      onClick={onClick}
    >
      {!selected && (
        <div className="program-bar__preview">
          <img className="program-bar__logo" src={logo} alt="" />
        </div>
      )}
      <div className="program-bar__body">
        <div className="program-bar__name">{name}</div>
        {/* <div className="program-bar__location">
          <Location>{location}</Location>
        </div> */}
      </div>
      <div className="program-bar__category">{category}</div>
    </button>
  );
}

export default ProgramBar;
