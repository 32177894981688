import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as actions from "../../actions/actions";
import * as selectors from "../../selectors/selectors";
import Label from "../Label/label";
import Helper from "../Helper/helper";
import Explainer from "../../Components/Explainer/explainer";
import AppInput from "../AppInput/app_input";

function Event_Pass() {
  const dispatch = useDispatch();

  const event_pass = useSelector(selectors.getEventPass);
  const event_address = useSelector(selectors.getEventAddress);

  const [value, setValue] = useState({
    ref: event_pass ? event_pass : "",
    address: event_address ? event_address : "",
  }); //* To set all values in this.
  return (
    <div className="form">
      <div className="form-content">
        <div className="voucher__widget">
          <Label>
            Event name
            <Explainer
              title="
Enter the name of the event you are applying the voucher to. 
                "
              size="20"
            />
          </Label>
          <AppInput
            maxLength={20} // Enforces a 20-character limit
            placeholder="Add text"
            value={value.ref}
            onChange={(e) => {
              setValue({ ...value, ref: e.target.value });
              dispatch({ type: "SET_EVENT_PASS", payload: e.target.value });
            }}
          />
        </div>
        <div className="voucher__widget">
          <Label>Event address</Label>
          <AppInput
            tag="textarea"
            rows="2"
            placeholder="Add text"
            value={value.address}
            onChange={(e) => {
              setValue({ ...value, address: e.target.value });
              dispatch({ type: "SET_EVENT_ADDRESS", payload: e.target.value });
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Event_Pass;
