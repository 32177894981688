import {
  SET_NAME,
  SET_EMAIL,
  SET_ID,
  SET_UUID,
  SET_PROFILE_IMAGE_ID,
  SET_OTP,
  LOGOUT,
  SET_LIMIT_CHECK,
  SET_CHECK_RESTART,
  SET_ANALYTICS,
  SET_CURRENT_SUBSCRIPTION,
  SET_SUPER_ADMIN,
  SET_IMPERSONATING_MERCHANT_ID,
  SET_MERCHANT_USER_ROLE,
  SET_ACCOUNT_UUID_TO_LOG_IN,
} from "../actions/actionTypes";

/**
 * @typedef InitialState
 * @property {string|null} name
 * @property {string|null} email
 * @property {string|null} id
 * @property {string|null} uuid
 * @property {string|null} otp
 * @property {string|null} profileImageId
 * @property {number|null} currentSubscription
 * @property {string|null} accountUuidToLogIn
 */

/** @type {InitialState} */
const initialState = {
  name: null,
  email: null,
  id: null,
  uuid: null,
  profileImageId: null,
  otp: null,
  limitcheck: null,
  checkRestart: null,
  analytics: null,
  currentSubscription: null,
  merchantUserRole: null,
  superAdmin: null,
  accountUuidToLogIn: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case SET_ACCOUNT_UUID_TO_LOG_IN:
      return {
        ...state,
        accountUuidToLogIn: action.payload,
      };
    case SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case SET_UUID:
      return {
        ...state,
        uuid: action.payload,
      };
    case SET_OTP:
      return {
        ...state,
        otp: action.payload,
      };
    case SET_LIMIT_CHECK:
      return {
        ...state,
        limitcheck: action.payload,
      };
    case SET_CHECK_RESTART:
      return {
        ...state,
        checkRestart: action.payload,
      };
    case SET_ANALYTICS:
      return {
        ...state,
        analytics: action.payload,
      };
    case SET_IMPERSONATING_MERCHANT_ID:
      return {
        ...state,
        impersonatingMerchantId: action.payload,
      };
    case SET_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        currentSubscription: action.payload,
      };
    case SET_MERCHANT_USER_ROLE:
      return {
        ...state,
        merchantUserRole: action.payload,
      };
    case SET_SUPER_ADMIN:
      return {
        ...state,
        superAdmin: action.payload,
      };
    case SET_PROFILE_IMAGE_ID:
      return {
        ...state,
        profileImageId: action.payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
