import React, { useCallback, useEffect, useState } from "react";
import styles from "./user-management.module.css";
import { ReactComponent as DeleteIcon } from "../../assets/symbols/delete.svg";
import { ReactComponent as PlusHeavyIcon } from "../../assets/symbols/plus-rounded.svg";
import TempAvatar from "../../assets/temp/deal-photo-coffee-place@3x.jpg";
import AppButton from "../../Components/AppButton/app_button";
import Choice from "../../Components/Choice/choice";
import CreateUser from "../../Components/CreateUser/create-user";
import {
  changeUserRole,
  deleteUser,
  getUsers,
} from "../../services/userManagementService";
import classNames from "classnames";
import { handleActionNotification } from "../../utils/utils";
import { notification } from "antd";
import useResendInvite from "../../Hooks/resendInvite";
import { ReactComponent as RefreshIcon } from "../../assets/symbols/refresh.svg";
import { useSelector } from "react-redux";
import * as selectors from "../../selectors/selectors";
import { User } from "../../types";

function mapMerchantUserRoleIdToRole(
  merchantUserRoleId: number,
): "OWNER" | "ADMIN" | "USER" | "SUPERADMIN" | undefined {
  console.log("===============", merchantUserRoleId);
  switch (merchantUserRoleId) {
    case 1:
      return "OWNER";
    case 2:
      return "ADMIN";
    case 3:
      return "USER";
    case 4:
      return "SUPERADMIN";
    default:
      return undefined;
  }
}

function mapRoleToMerchantUserRoleId(role: string): number | undefined {
  switch (role) {
    case "OWNER":
      return 1;
    case "ADMIN":
      return 2;
    case "USER":
      return 3;
    case "SUPERADMIN":
      return 4;
  }

  return;
}

function UserManagement() {
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const accountId = useSelector(selectors.getAccountId);
  const accountUuid = useSelector(selectors.getAccountUuid);

  const [users, setUsers] = useState<User[]>();

  const resendInvite = useResendInvite();

  const refresh = useCallback(async () => {
    if (!accountUuid) {
      return;
    }
    setIsLoading(true);
    try {
      setUsers(await getUsers(accountUuid));
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleResendInvite = async (name: string, email: string) => {
    try {
      await resendInvite(name, email, accountUuid);
      notification.success({
        message: "Invitation resent successfully",
      });
    } catch (error) {
      notification.error({
        message: "Invitation failed to resend",
      });
    }
  };

  return (
    <>
      {/* <div> */}
      <div className={classNames(styles["user-management"], "container")}>
        <div className={styles["user-management__heading"]}>
          <h1 className={"title"}>User Management</h1>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <button
              className="button button--almost-a-square button--secondary"
              type="button"
              disabled={isLoading}
              onClick={async () => {
                refresh();
              }}
            >
              <RefreshIcon className="button__icon" />
            </button>
            <button
              onClick={() => {
                setShowNewUserModal(true);
              }}
              className={styles["user-management__add-button"]}
            >
              <PlusHeavyIcon /> Add User
            </button>
          </div>
        </div>
        <div className={styles["user-management__body"]}>
          {isLoading ? (
            <>Loading...</>
          ) : (
            <fieldset
              disabled={isDisabled}
              className={styles["user-management__body__fieldset"]}
            >
              <table>
                <thead>
                  <tr>
                    <th>Avatar</th>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Status</th>
                    <th>Role</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {users
                    ?.filter((user) => user.active)
                    .map((user) => {
                      const role = mapMerchantUserRoleIdToRole(
                        user?.accountMemberships[0]?.userRole?.id,
                      );
                      return (
                        <tr key={user.id}>
                          <td>
                            <img
                              className={styles["user-management__avatar"]}
                              src={
                                user.profileImageId
                                  ? `${process.env.REACT_APP_CDN_URL}${user.profileImageId}.jpg`
                                  : TempAvatar
                              }
                              alt=""
                            />
                          </td>
                          <td>{user.name}</td>
                          <td style={{ textAlign: "center" }}>
                            <a className={styles["user-management__email"]}>
                              {user.email}
                            </a>
                          </td>
                          <td>
                            {user.accountMemberships[0].emailInviteAccepted ? (
                              user.accountMemberships[0].active ? (
                                "Accepted"
                              ) : (
                                "Removed"
                              )
                            ) : (
                              <div>
                                Invitation Sent
                                <div
                                  style={{ color: "blue", cursor: "pointer" }}
                                  onClick={() => {
                                    handleResendInvite(user.name, user.email);
                                  }}
                                >
                                  Resend Invitation
                                </div>
                              </div>
                            )}
                          </td>
                          <td>
                            <div className={styles["user-management__roles"]}>
                              {role === "OWNER"
                                ? "Owner"
                                : [
                                    { value: "ADMIN", label: "Admin" },
                                    { value: "USER", label: "User" },
                                  ].map(({ value, label }) => (
                                    <Choice
                                      key={value}
                                      type="radio"
                                      name={`user-${user.id}-role`}
                                      checked={role === value}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          handleActionNotification(
                                            () => {
                                              const merchantUserRoleId =
                                                mapRoleToMerchantUserRoleId(
                                                  value,
                                                );

                                              if (!merchantUserRoleId) {
                                                throw new Error(
                                                  "merchantUserRoleId is empty",
                                                );
                                              }
                                              setIsDisabled(true);
                                              changeUserRole(
                                                user.id,
                                                merchantUserRoleId,
                                                accountId,
                                              )
                                                .then(() => {
                                                  refresh();
                                                })
                                                .catch(() => {
                                                  notification.warning({
                                                    message:
                                                      "An error occurred while updating the user role. Please try again or contact customer service.",
                                                  });
                                                })
                                                .finally(() => {
                                                  setIsDisabled(false);
                                                });
                                            },
                                            "Would you like to update the role?",
                                            "Cancel",
                                            "Yes",
                                          );
                                        }
                                      }}
                                    >
                                      {label}
                                    </Choice>
                                  ))}
                            </div>
                          </td>
                          <td>
                            {role !== "OWNER" && (
                              <AppButton
                                variant="secondary"
                                almostASquare
                                leftIcon={DeleteIcon}
                                onClick={() => {
                                  handleActionNotification(
                                    () => {
                                      setIsDisabled(true);
                                      deleteUser(user.id, accountId)
                                        .then(() => {
                                          refresh();
                                        })
                                        .catch(() => {
                                          notification.warning({
                                            message:
                                              "An error occurred while deleting the user. Please try again or contact customer service.",
                                          });
                                        })
                                        .finally(() => {
                                          setIsDisabled(false);
                                        });
                                    },
                                    "Are you sure you want to delete this user?",
                                    "Cancel",
                                    "Delete",
                                  );
                                }}
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </fieldset>
          )}
        </div>
      </div>
      {showNewUserModal && (
        <CreateUser
          onCancel={() => setShowNewUserModal(false)}
          onSuccess={() => {
            refresh();
            setShowNewUserModal(false);
          }}
        />
      )}
    </>
    // </div>
  );
}

export default UserManagement;
