import axiosInstance from "../services/axiosIntercept";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";
import * as actions from "../actions/actions";

const useUpdateProfile = () => {
  const dispatch = useDispatch();
  const merchantUserId = useSelector(selectors.getId);

  const updateProfile = (imgSha, name) => {
    try {
      const payload = {
        name: name,
        profilePic: imgSha,
        merchantUserId: merchantUserId,
      };

      console.log(payload);

      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}v2/business/update/profile`,
          payload,
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          console.log(res.data);
          dispatch(actions.setName(res.data.name));
          dispatch(actions.setProfileImage(res.data.profileImageId));
        })
        .catch((err) => {
          console.error("Error while updating the user profile.", err);
        });
    } catch (err) {
      console.error("Error while updating the user profile.", err);
    }
  };

  return updateProfile;
};

export default useUpdateProfile;
