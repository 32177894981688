import axiosInstance from "../services/axiosIntercept";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";

const useUpdatePaymentApproval = () => {
  const dispatch = useDispatch();
  const id = useSelector(selectors.getAccountId);

  const updatePaymentApproval = async (transactionDetails) => {
    console.log("Transaction Details:", transactionDetails);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}v1/payments/paypal/subscriptions`,
        {
          accountId: id,
          planId: transactionDetails?.planId,
          orderId: transactionDetails?.data?.orderID || " ",
          result: transactionDetails?.success ? "Approved" : "Rejected",
          subscriptionId: transactionDetails?.data?.subscriptionID || " ",
        },
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        },
      );

      const updatedPaymentApproval = response.data;
      console.log("Updated Payment Approval:", updatedPaymentApproval);
    } catch (error) {
      console.error("Error while updating payment approval:", error);
      throw error;
    }
  };

  return updatePaymentApproval;
};

export default useUpdatePaymentApproval;
