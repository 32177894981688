import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as actions from "../../actions/actions";
import * as selectors from "../../selectors/selectors";
import Label from "../Label/label";
import Helper from "../Helper/helper";
import Explainer from "../../Components/Explainer/explainer";
import AppInput from "../AppInput/app_input";

function Giveaway() {
  const dispatch = useDispatch();

  const giveaway = useSelector(selectors.getGiveaway);
  const special_address = useSelector(selectors.getSpecialAddress);

  const [value, setValue] = useState({
    ref: giveaway ? giveaway : "",
    address: special_address ? special_address : "",
  }); //* To set all values in this.
  return (
    <div className="form">
      <div className="form-content">
        <div className="voucher__widget">
          <Label>
            Product or service
            <Explainer
              title="
Enter the name of the product or service 
you are applying the voucher to. If everything, type 'everything'!
                "
              size="20"
            />
          </Label>
          <AppInput
            maxLength={20} // Enforces a 20-character limit
            placeholder="Example: Blue cheese or new haircut"
            value={value.ref}
            onChange={(e) => {
              setValue({ ...value, ref: e.target.value });
              dispatch(actions.setGiveaway(e.target.value));
            }}
          />
          <Helper>Maximum 20 characters</Helper>
        </div>
        <div className="voucher__widget">
          <Label>Giveaway address</Label>
          <AppInput
            tag="textarea"
            rows="2"
            placeholder="Add text"
            value={value.address}
            onChange={(e) => {
              setValue({ ...value, address: e.target.value });
              dispatch(actions.setSpecialAddress(e.target.value));
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Giveaway;
