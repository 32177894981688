import React, { useRef, useCallback, useState } from "react";
import "../Verification/verification_page.css";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import * as actions from "../../actions/actions";
import * as selectors from "../../selectors/selectors";
import OtpInput from "react-otp-input";
// will be applied to OtpInput inputs:
import "../../Components/AppInput/app_input.css";
import AppButton from "../../Components/AppButton/app_button";
import Popup from "../../Components/Popup/popup";
import { Turnstile } from "@marsidev/react-turnstile";
import { useNavigate, useParams } from "react-router-dom";
import useUpdateEmail from "../../Hooks/updateEmail";

function Email_Verification_Page() {
  const params = useParams();
  const dispatch = useDispatch();
  const [turnstileRequireInteractive, setTurnstileRequireInteractive] =
    useState(false);

  const turnstileRef = useRef(
    /** @type {import("@marsidev/react-turnstile").TurnstileInstance} */ (null),
  );

  const updateEmail = useUpdateEmail();
  const navigate = useNavigate();

  const [ver_code, setver_code] = useState("");
  const [token, setToken] = useState(params.token || "");

  //   const turnstileToken = turnstileRef.current?.getResponse();

  //   if (!turnstileToken) {
  //     notification.error({
  //       message: "Captcha Validation Failed",
  //       description: "Please complete the captcha challenge.",
  //     });
  //     return;
  //   }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ver_btn = document.getElementById("ver-btn-dis");
    ver_btn.disabled = true;

    if (/^\d{4}$/.test(ver_code)) {
      dispatch(actions.setOtp(ver_code));
      notification.info({
        message:
          "We are currently in the process of verifying, Please wait a moment",
      });

      try {
        updateEmail(ver_code, token, turnstileRef.current?.getResponse());
        notification.success({
          message: "Email Updated Successfully",
          description: "You can now login with your new email.",
        });
        navigate("/");
      } catch (err) {
        console.error(err);
        dispatch(actions.setAuthError(err));
        notification.error({
          message: "Invalid OTP",
          description: `The OTP you entered is incorrect or has expired.`,
        });
      } finally {
        ver_btn.disabled = false;
      }
    } else {
      notification.info({
        message: "Invalid OTP. Please enter a four-digit number.",
      });
      ver_btn.disabled = false;
    }
  };

  const closepopup = () => {
    window.history.back();
  };
  return (
    <Popup disableOverlayClick onCloseClick={closepopup}>
      <form className="auth auth--otp-verification">
        <div className="auth__heading">Enter the Verification Code</div>
        <div className="auth__note">
          Enter the 4-digit one time password that we sent to your email address{" "}
        </div>
        <div className="auth__panel">
          <div className="split-input">
            <OtpInput
              value={ver_code}
              onChange={setver_code}
              numInputs={4}
              inputType={"tel"}
              shouldAutoFocus={true}
              skipDefaultStyles={true}
              renderInput={(props) => <input {...props} />}
              inputStyle="input"
            />
          </div>
          <div className="auth__code-hint">The code is valid for an hour</div>
        </div>
        <div className="auth__optional-turnstile-capcha">
          <div
            className={`turnstile ${
              turnstileRequireInteractive ? "turnstile--interactive" : ""
            }`}
          >
            <Turnstile
              siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
              options={{
                appearance: "interaction-only",
              }}
              onError={() => console.log("captcha validation failed")}
              onBeforeInteractive={() => {
                setTurnstileRequireInteractive(true);
              }}
              ref={turnstileRef}
            />
          </div>
        </div>
        <div className="auth__submit" onClick={handleSubmit}>
          <AppButton
            fullWidth
            uniqueModifier="submit"
            type="submit"
            id="ver-btn-dis"
          >
            Next Step
          </AppButton>
        </div>
      </form>
    </Popup>
  );
}

export default Email_Verification_Page;
