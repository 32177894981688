import axiosInstance from "../services/axiosIntercept";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";
import * as actions from "../actions/actions";
import { AxiosResponse } from "axios";
import { LimitCheckAlertStatus } from "../types";

const useLimitCheck = () => {
  const dispatch = useDispatch();
  const uuid = useSelector(selectors.getAccountUuid);
  const getLimitCheck = async () => {
    if (!uuid) {
      return;
    }

    try {
      axiosInstance
        .post<
          any,
          AxiosResponse<LimitCheckAlertStatus>,
          { accountUUID: string }
        >(
          `${process.env.REACT_APP_API_URL}v2/merchantSetup/check`,
          { accountUUID: uuid },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
            },
          },
        )
        .then((res) => {
          dispatch(actions.setLimitcheck(res.data));
          console.log("Limit Check", res.data);
        });
    } catch (error) {
      console.error("Error while Limit Check:", error);
      throw error;
    }
  };

  return getLimitCheck;
};

export default useLimitCheck;
