import React from "react";
import "./poster.css";

import { hexToRgb } from "../../utils/utils";
import PoweredByMeed from "../PoweredByMeed/powered_by_meed";

/**
 * @param {object} props
 * @param {import("../../types").PosterDataProp} props.data
 * @param {string} [props.variant]
 * @returns {import("react").ReactNode}
 */
function Poster({ data, variant }) {
  const backgroundAsRGB =
    hexToRgb(data.brandColor).r +
    " " +
    hexToRgb(data.brandColor).g +
    " " +
    hexToRgb(data.brandColor).b;

  return (
    <div className="poster">
      <div
        className="poster__body"
        style={{
          backgroundImage:
            "linear-gradient(to bottom, rgba(" +
            backgroundAsRGB +
            " / 5%), rgba(" +
            backgroundAsRGB +
            " / 100%))",
        }}
      >
        <div className="poster__background-noise">
          <div className="poster__aligning">
            <div className="poster__head">
              <div className="poster__join">
                <img
                  src={`${process.env.REACT_APP_QR_CODE_URL}${data.programUuid}`}
                  alt="Business QR"
                />
              </div>
              <div className="poster__summary">
                Scan to become a member. Powered by Meed.
              </div>
            </div>
            <div className="poster__cut-card">
              <div className="poster__card">
                <div className="poster__middle-background"></div>
                <div className="poster__top-background"></div>
                <div className="poster__bottom-background"></div>
                <div className="poster__inner-card">
                  <div className="poster__about">
                    <div className="poster__logo">
                      <img
                        src={`${process.env.REACT_APP_CDN_URL}${data.businessLogo}.jpg`}
                        alt="Business Logo"
                      />
                    </div>
                    <div className="poster__data">
                      <div className="poster__name">{data.businessName}</div>
                      <div className="poster__location">{data.address}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="poster__footer">
            <PoweredByMeed />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Poster;
