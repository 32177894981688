import React from "react";
import "./detailed_preview.css";
import { ReactComponent as ChevronLeftMediumIcon } from "../../assets/symbols/chevron-left-medium.svg";

function DetailedPreview({ children }) {
  return (
    <div className="detailed-preview">
      <div className="detailed-preview__back">
        <ChevronLeftMediumIcon className="detailed-preview__chevron" />
        Offer details
      </div>
      <div className="detailed-preview__body">{children}</div>
    </div>
  );
}

export default DetailedPreview;
