import styles from "./index.module.css";

export default function CampaignPicturePlaceholder() {
  return (
    <div className={styles["campaign-picture-placeholder"]}>
      <div className={styles["campaign-picture-placeholder__text"]}>
        Voucher image
      </div>
    </div>
  );
}
