import React from "react";
import { useLocation } from "react-router-dom";
import PdfPage from "./pdfPage";

function PdfPageWrapper() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const data = {
    businessName: queryParams.get("businessName"),
    businessLogo: queryParams.get("businessLogo"),
    programUuid: queryParams.get("programUuid"),
    address: queryParams.get("address"),
    brandColor: queryParams.get("brandColor"),
    size: queryParams.get("size"),
    offer:
      "Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer ",
  };

  return <PdfPage data={data} />;
}

export default PdfPageWrapper;
