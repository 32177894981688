import axiosInstance from "../services/axiosIntercept";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";
import * as actions from "../actions/actions";

const useCheckRestart = () => {
  const dispatch = useDispatch();
  const programId = useSelector(selectors.getSelectedLp);

  const checkRestart = async () => {
    try {
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}v1/voucher/checkRestart`,
          { programId: programId },
          {
            withCredentials: true,
            headers: {
              "api-key": process.env.REACT_APP_API_KEY,
            },
          },
        )
        .then((res) => {
          console.log(res);
          dispatch(actions.setCheckRestart(res.data));
        });
    } catch (error) {
      console.error("API Error:", error.response.status, error.response.data);
      throw error;
    }
  };

  return checkRestart;
};

export default useCheckRestart;
