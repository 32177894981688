import React, { useEffect, useState } from "react";
import styles from "./profile.module.css";
import { notification } from "antd";
import Label from "../../Components/Label/label";
import AppInput from "../../Components/AppInput/app_input";
import Popup from "../../Components/Popup/popup";
import { createNewUser } from "../../services/userManagementService";
import { useSelector } from "react-redux";
import {
  getUuid,
  getMerchantUserRole,
  getCurrentSubscription,
  getName,
  getEmail,
  getUserAccountRole,
  getAccountSubscription,
} from "../../selectors/selectors";
import Uploader from "../Uploader/uploader";
import editIcon from "../../assets/icons/edit.svg";
import EditProfile from "../EditProfile/editProfile";
import useUpdateProfile from "../../Hooks/updateProfile";
import organization from "../../assets/icons/organization.svg";
import AddOrganization from "../AddOrganization/addOrganization";

/**
 *
 * @param {object} props
 * @param {{ name: string; email: string; image: string;}} props.data
 * @param {() => void} props.onCancel
 * @param {() => void} props.onSuccess
 * @returns {import("react").JSX.Element}
 */
function Profile({ data, onCancel, onSuccess }) {
  const accountUuid = useSelector(getUuid);
  const userRole = useSelector(getUserAccountRole);
  const subscription = useSelector(getAccountSubscription);
  const merchantName = useSelector(getName);
  const merchantEmail = useSelector(getEmail);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [updateName, setUpdateName] = useState(false);
  const [updateEmail, setUpdateEmail] = useState(false);
  const updateProfile = useUpdateProfile();
  const [addOrganization, setAddOrganization] = useState(false);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("USER");
  const [selectedImageSHA, setSelectedImageSHA] = useState(
    data ? data.image : null,
  );

  console.log(data);
  console.log(subscription);

  let subscriptionMessage;
  if (subscription === 1) {
    subscriptionMessage = "Monthly Premium";
  } else if (subscription === 2) {
    subscriptionMessage = "Annual Premium";
  } else {
    subscriptionMessage = "Free";
  }

  useEffect(() => {
    const emailRegex = /\S+@\S+\.\S+/;

    // Check if the inputEmail matches the emailRegex
    setIsFormValid(emailRegex.test(email) && name !== "");
  }, [email, name]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    notification.info({
      message: "Updating merchant profile..",
      description: `Please wait...`,
    });

    // try {
    //   updateProfile(selectedImageSHA, merchantName);
    // } catch (err) {
    //   console.error(err);
    //   notification.error({
    //     message: "Error",
    //     description: "Please try again later",
    //   });
    // }
  };

  return (
    <Popup disableOverlayClick onCloseClick={onCancel}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <fieldset disabled={isSubmitting}>
          <div className={styles.title}>Personal Information</div>

          <div className={styles.field}>
            <Label>Name</Label>
            <div className={styles.subfields}>
              {merchantName}
              <img
                src={editIcon}
                alt="edit"
                onClick={() => {
                  setUpdateName(true);
                }}
                style={{ cursor: "pointer", height: "25px", width: "25px" }}
              />
            </div>
          </div>

          <div className={styles.field}>
            <Label>Email</Label>
            <div className={styles.subfields}>
              {merchantEmail}
              {userRole === "OWNER" ? (
                <img
                  src={editIcon}
                  alt="edit"
                  onClick={() => setUpdateEmail(true)}
                  style={{
                    cursor: "pointer",
                    height: "25px",
                    width: "25px",
                  }}
                />
              ) : null}
            </div>
          </div>

          <div className={styles.field}>
            <Label>Role</Label>
            <div className={styles.subfields}>{userRole}</div>
          </div>

          <div className={styles.field}>
            <Label>Organization</Label>
            <div className={styles.subfields}>
              <img
                src={organization}
                alt="edit"
                onClick={() => {
                  setAddOrganization(true);
                }}
                style={{ cursor: "pointer", height: "25px", width: "25px" }}
              />
            </div>
          </div>

          <div className={styles.field}>
            <Label>Organization Status</Label>
            <div className={styles.subfields}>{subscriptionMessage}</div>
          </div>

          <div className={styles.img_upload}>
            <Label>Edit Avatar</Label>
            <Uploader
              type="image"
              width={315}
              height={315}
              message="Upload your Avatar"
              defaultImageSrc={data?.imageURL}
              onUploaded={(sha) => {
                console.log(sha);
                updateProfile(sha, merchantName);
                notification.info({
                  message: "profile Image updated!!",
                });
              }}
              aspectRatio={1}
            />
            <div className={styles["field__helper-text"]}>
              Image size must be below 2MB
            </div>
          </div>

          <div className={styles.field}>
            <Label>Account</Label>
          </div>

          <div className={styles.buttons}>
            {userRole !== "USER" ? (
              <button
                type="button"
                className="button button--secondary button--full-width"
                onClick={() => {
                  window.open(`https://www.meedloyalty.com/data-deletion`);
                }}
              >
                Request to delete account
              </button>
            ) : (
              <button
                type="button"
                className="button button--secondary button--full-width"
              >
                Request to remove account from organisation
              </button>
            )}
          </div>
        </fieldset>
        {updateName && (
          <EditProfile
            type="Name"
            data={data.name}
            imgSha={selectedImageSHA}
            onSuccess={() => {
              setUpdateName(true);
            }}
            onCancel={() => {
              setUpdateName(false);
            }}
          />
        )}

        {updateEmail && (
          <EditProfile
            type="Email"
            data={data.email}
            imgSha={selectedImageSHA}
            onSuccess={() => {
              setUpdateEmail(true);
            }}
            onCancel={() => {
              setUpdateEmail(false);
            }}
          />
        )}

        {addOrganization && (
          <AddOrganization onCancel={() => setAddOrganization(false)} />
        )}
      </form>
    </Popup>
  );
}

export default Profile;
