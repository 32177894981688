import { React, useCallback, useState } from "react";
import "./pdfPage.css";
import { Margin, usePDF } from "react-to-pdf";

import Poster from "../../Components/Poster/poster";
import A4Voucher from "../../Components/A4Voucher/a4_voucher";
import A5Voucher from "../../Components/A5Voucher/a5_voucher";
import A6Voucher from "../../Components/A6Voucher/a6_voucher";

/**
 * @param {object} props
 * @param {import("../../types").PosterDataProp} props.data
 * @returns {import("react").ReactNode}
 */
function PdfPage({ data }) {
  const [activeTab, setActiveTab] = useState(data.size ?? "a4poster");

  /** @type {Record<string, {label: string; content: import("react").ReactNode}>} */
  const tabs = {
    a4poster: { label: "A4 poster", content: <Poster data={data} /> },
    a4voucher: {
      label: "A4 voucher",
      content: <A4Voucher data={data} />,
    },
    a5voucher: {
      label: "A5 voucher",
      content: <A5Voucher data={data} />,
    },
    a6voucher: {
      label: "A6 voucher",
      content: <A6Voucher data={data} />,
    },
  };

  const onBack = useCallback(() => {
    window.close();
  }, []);

  let pageSize;

  if (activeTab === "a4poster" || activeTab === "a4_voucher") {
    pageSize = "a4";
  }
  if (activeTab === "a5voucher") {
    pageSize = "a5";
  }
  if (activeTab === "a6voucher") {
    pageSize = "a6";
  }

  const { toPDF, targetRef } = usePDF({
    filename: "meed-voucher.pdf",
    page: { margin: Margin.NONE, format: pageSize },
  });

  return (
    <div className="pdf-page">
      <div className="pdf-page__container">
        {/*These are tabs originally presented on the page. Now it's replaces by the page with shop illustration, but these are still good for testing: */}
        {/*<div className="pdf-page__choice">*/}
        {/*  {Object.entries(tabs).map(([key, { label }]) => (*/}
        {/*    <button*/}
        {/*      key={key}*/}
        {/*      onClick={() => setActiveTab(key)}*/}
        {/*      className={key === activeTab ? "active" : ""}*/}
        {/*    >*/}
        {/*      {label}*/}
        {/*    </button>*/}
        {/*  ))}*/}
        {/*</div>*/}
        <a
          className="pdf-page__back"
          onClick={onBack}
          style={{ cursor: "pointer" }}
        >
          ← Close
        </a>
        <div className="pdf-page__print-handler">
          <button
            className="button"
            onClick={() => {
              toPDF();
            }}
          >
            Print
          </button>
        </div>
        <div className="pdf-page__viewport">
          <div className="pdf-page__print-area" ref={targetRef}>
            {tabs[activeTab].content}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PdfPage;
