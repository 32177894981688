import React from "react";
import "./title.css";

function Title(props) {
  return (
    <span
      className={`title${props.secondary ? " title--secondary" : ""}${
        props.tertiary ? " title--tertiary" : ""
      }`}
    >
      {props.children}
    </span>
  );
}

export default Title;
