import React, { FormEventHandler, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { DeviceUUIDComponent } from "../../device_id";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import * as actions from "../../actions/actions";
import Label from "../../Components/Label/label";
import AppInput from "../../Components/AppInput/app_input";
import Popup from "../../Components/Popup/popup";
import CookieNotice from "../../Components/CookieNotice/cookie_notice";
import { getImpersonateOtp } from "../../services/impersonateService";
import styles from "./login_impersonate_page.module.css";
import AppButton from "../../Components/AppButton/app_button";
import OTPInput from "react-otp-input";
import { verifyOtp } from "../../services/verificationService";
import { useSelector } from "react-redux";
import {
  getAccountUuid,
  getAccountUuidToLogIn,
  getSelectedLp,
} from "../../selectors/selectors";
import useGetPrograms from "../../Hooks/getPrograms";
import useGetIndustries from "../../Hooks/getIndustries";
import { awaitNotEmpty } from "../../utils/utils";

const TURNSTILE_SITE_KEY = String(process.env.REACT_APP_TURNSTILE_SITE_KEY);

const UnauthorizedAccessWarning: React.FC = () => (
  <div className={styles["unauthorized-access-warning"]}>
    <div className={styles["unauthorized-access-warning__title"]}>
      Unauthorized Access Prohibited
    </div>
    <div className={styles["unauthorized-access-warning__text"]}>
      This web page access is intended for authorized users only. Unauthorized
      access to this web page and its contents is strictly prohibited and may be
      subject to legal action. By accessing this web page, you agree to comply
      with the terms and conditions of use. If you are not an authorized user,
      please exit this web page immediately.
    </div>
  </div>
);

const LoginImpersonatePage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const turnstileRef = useRef<TurnstileInstance>(null);
  const [turnstileRequireInteractive, setTurnstileRequireInteractive] =
    useState(false);
  const [impersonatorEmail, setImpersonatorEmail] = useState("");
  const [email, setEmail] = useState(searchParams.get("email") || "");
  const [accountUuid, setAccountUuid] = useState(
    searchParams.get("accountUuid") || "",
  );
  const [isGettingOtp, setIsGettingOtp] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const [step, setStep] = useState<"otp" | "login">("login");

  const uuid = useSelector(getAccountUuid);
  const selectedProgramId = useSelector(getSelectedLp);
  const accountUUuidToLogIn = useSelector(getAccountUuidToLogIn);

  const getPrograms = useGetPrograms(uuid, selectedProgramId);
  const getIndustries = useGetIndustries();

  const sendOtp = async () => {
    setIsGettingOtp(true);

    const deviceUUID = DeviceUUIDComponent();

    dispatch(actions.setAccountUuidToLogIn(accountUuid));

    // Regular expression for a simple email validation
    const emailRegex = /\S+@\S+\.\S+/;

    // Check if the inputEmail matches the emailRegex
    if (!emailRegex.test(email)) {
      notification.error({
        message: "Invalid Email",
        description:
          "Please enter a valid email address of the person to impersonate.",
      });
      setIsGettingOtp(false);
      return;
    }
    if (!emailRegex.test(impersonatorEmail)) {
      notification.error({
        message: "Invalid Email",
        description: "Please enter a valid email address of the impersonator.",
      });
      setIsGettingOtp(false);
      return;
    }

    const turnstileToken = await awaitNotEmpty(
      () => turnstileRef.current?.getResponse(),
    );

    if (!turnstileToken) {
      notification.error({
        message: "Captcha Validation Failed",
        description: "Please complete the captcha challenge.",
      });
      setIsGettingOtp(false);
      return;
    }

    notification.info({
      message: "Sending the OTP",
      description: `Please wait...`,
    });

    try {
      await getImpersonateOtp({
        deviceID: deviceUUID,
        email,
        impersonatorEmail,
        turnstileToken,
      });

      notification.info({
        message: "You will receive the OTP in a moment",
        description: `OTP has been sent to the impersonator email address ${impersonatorEmail}`,
      });

      dispatch(actions.setEmail(email));
      setStep("otp");
    } catch (err: any) {
      console.error(err);
      notification.error({
        message: (
          <>There's an error requesting your OTP for impersonating the user</>
        ),
      });
    } finally {
      setIsGettingOtp(false);
    }
  };

  const handleVerifyOtp: FormEventHandler = async (e) => {
    e.preventDefault();
    const deviceUUID = DeviceUUIDComponent();

    if (!/^\d{4}$/.test(otp)) {
      notification.info({
        message: "Invalid OTP. Please enter a four-digit number.",
      });
      return;
    }

    dispatch(actions.setOtp(otp));
    notification.info({
      message:
        "We are currently in the process of verifying, Please wait a moment",
    });
    setIsVerifyingOtp(true);

    try {
      const response = await verifyOtp({
        deviceID: deviceUUID,
        email,
        otpCode: otp,
        accountToLogIn: accountUUuidToLogIn || null,
      });

      if (!response.account) {
        dispatch(
          actions.setAuthError("Organization for the user is not found!"),
        );
        notification.error({
          message: "Authentication Failed. Please try again.",
        });
      }

      const defaultAccountSelectedRes = response.merchantUser.defaultAccount;

      // Set User Data
      dispatch(actions.setName(response.merchantUser.name));
      dispatch(actions.setId(response.merchantUser.id));
      dispatch(actions.setUuid(response.merchantUser.uuid));
      dispatch(actions.setProfileImage(response.merchantUser.profileImageId));

      const accounts = Array.isArray(response.account)
        ? response.account
        : [response.account];

      localStorage.setItem("NewUser", "false");

      console.log("Login Success", response);

      const defaultAccountSelected = accounts[0]?.account;
      const defaultRoleSelected = accounts[0]?.userRole.name;

      dispatch(actions.setMerchantUserRole(defaultRoleSelected));

      // Set Account Data
      dispatch(actions.setAccounts(accounts));
      const defaultAccountAndUserRole = accounts[0];
      if (defaultAccountSelectedRes) {
        dispatch(actions.setAccount(defaultAccountSelectedRes));
      } else {
        if (defaultAccountAndUserRole) {
          dispatch(actions.setAccount(defaultAccountAndUserRole));
        }
      }
      dispatch(actions.setIsAuthenticated());

      notification.success({
        message: "Verification completed",
      });

      if (defaultAccountSelected) {
        getPrograms(defaultAccountSelected.uuid);
      }
      getIndustries();
      // getCountries();
      // getOverview(response.data.merchant.uuid);

      navigate("/dashboard");
    } catch (err) {
      console.error(err);
      dispatch(actions.setAuthError(err));
      notification.error({
        message: "Invalid OTP",
        description: `The OTP you entered is incorrect or has expired.`,
      });
    } finally {
      setIsVerifyingOtp(false);
    }
  };

  return (
    <>
      {step === "otp" ? (
        <Popup
          disableOverlayClick
          onCloseClick={() => {
            setStep("login");
          }}
        >
          <form
            className="auth auth--otp-verification"
            onSubmit={handleVerifyOtp}
          >
            <fieldset
              className={styles.fieldset}
              disabled={isGettingOtp || isVerifyingOtp}
            >
              <div className="auth__heading">Enter the Verification Code</div>
              <div className="auth__note">
                Enter the 4-digit one time password that we sent to your email
                address
              </div>
              <div className="auth__panel">
                <div className="split-input">
                  <OTPInput
                    value={otp}
                    onChange={(value) => {
                      setOtp(value);
                    }}
                    numInputs={4}
                    inputType={"tel"}
                    shouldAutoFocus={true}
                    skipDefaultStyles={true}
                    renderInput={(props) => <input {...props} />}
                    inputStyle="input"
                  />
                </div>
                <div className="auth__code-hint">
                  The code is valid for an hour
                </div>
              </div>
              <div className="auth__optional-turnstile-capcha">
                <div
                  className={`turnstile ${
                    turnstileRequireInteractive ? "turnstile--interactive" : ""
                  }`}
                >
                  <Turnstile
                    siteKey={TURNSTILE_SITE_KEY}
                    options={{
                      appearance: "interaction-only",
                    }}
                    onError={() => console.log("captcha validation failed")}
                    onBeforeInteractive={() => {
                      setTurnstileRequireInteractive(true);
                    }}
                    ref={turnstileRef}
                  />
                </div>
              </div>
              <div className="auth__submit">
                <AppButton
                  fullWidth
                  uniqueModifier="submit"
                  type="submit"
                  id="ver-btn-dis"
                >
                  Next Step
                </AppButton>
              </div>
              <div className="auth__alternation">
                Don't receive anything?{" "}
                <button
                  className="auth__alt-action"
                  onClick={() => {
                    sendOtp();
                  }}
                  id="sendOTP-btn-dis"
                  type="button"
                >
                  Resend Code
                </button>
              </div>
            </fieldset>
            <UnauthorizedAccessWarning />
          </form>
        </Popup>
      ) : (
        <Popup disableOverlayClick>
          <form
            className="auth auth--login-screen"
            onSubmit={(e) => {
              e.preventDefault();
              sendOtp();
            }}
          >
            <fieldset className={styles.fieldset} disabled={isGettingOtp}>
              <div className="auth__main-heading">
                Authentication
                <span className="auth__sub-heading">Sign In</span>
              </div>
              <div className="auth__main-note">Delegated Account Access</div>

              <div className="auth__field">
                <Label>User Email Address</Label>
                <AppInput
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="auth__field">
                <Label>Authorized Email Address</Label>
                <AppInput
                  name="impersonatorEmail"
                  placeholder="Email"
                  value={impersonatorEmail}
                  onChange={(e) => {
                    setImpersonatorEmail(e.target.value);
                  }}
                />
              </div>
              <div className="auth__optional-turnstile-capcha">
                <div
                  className={`turnstile ${
                    turnstileRequireInteractive ? "turnstile--interactive" : ""
                  }`}
                >
                  <Turnstile
                    siteKey={TURNSTILE_SITE_KEY}
                    options={{
                      appearance: "interaction-only",
                    }}
                    onError={() => console.log("captcha validation failed")}
                    onBeforeInteractive={() => {
                      setTurnstileRequireInteractive(true);
                    }}
                    ref={turnstileRef}
                  />
                </div>
              </div>
              <div className="auth__submit">
                <button
                  type="submit"
                  className="button button--submit button--full-width"
                >
                  Impersonate
                </button>
              </div>
            </fieldset>
            <UnauthorizedAccessWarning />
          </form>
        </Popup>
      )}
      <CookieNotice />
    </>
  );
};

export default LoginImpersonatePage;
