import React from "react";
import "./socialPoster-voucher.css";
import PoweredByMeed from "../PoweredByMeed/powered_by_meed";
import { useLocation } from "react-router-dom";
import { toPng, toJpeg } from "html-to-image";
import { useRef, useCallback } from "react";
import { Button } from "antd";

/**
 * @param {object} data
 * @param {string} data.businessName
 * @param {string} data.businessLogo
 * @param {string} data.programUuid
 * @param {string} data.industry
 * @param {string} data.address
 * @param {string} data.brandColor
 * @param {string} data.size
 * @param {string} data.heroImageId
 */
const openScoialPage = (data) => {
  const queryParams = new URLSearchParams(data).toString();

  const url = `/pdf?${queryParams}`;
  window.open(url, "_blank");
};

function SocialPoster() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = useRef();

  const downloadImage = async (type) => {
    if (type === "png") {
      const dataUrl = await toPng(ref.current);
      download(dataUrl, "meed-social-poster.png");
    } else if (type === "jpeg") {
      const dataUrl = await toJpeg(ref.current);
      download(dataUrl, "meed-social-poster.jpeg");
    }
  };

  const download = (dataUrl, filename) => {
    const link = document.createElement("a");
    link.download = filename;
    link.href = dataUrl;
    link.click();
  };
  const onBack = useCallback(() => {
    window.close();
  }, []);
  const data = {
    businessName: queryParams.get("businessName"),
    businessLogo: queryParams.get("businessLogo"),
    programUuid: queryParams.get("programUuid"),
    address: queryParams.get("address"),
    brandColor: queryParams.get("brandColor"),
    industry: queryParams.get("industry"),
    offer:
      "Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer ",
    heroImageId: queryParams.get("heroBannerLogo"),
  };
  return (
    <>
      <div className="social-voucher-poster">
        <a
          className="social-poster-close-button"
          onClick={onBack}
          style={{ cursor: "pointer" }}
        >
          ← Close
        </a>
        <div
          className="social-voucher-poster-container"
          style={{ backgroundColor: data.brandColor }}
          ref={ref}
        >
          <div className="social-voucher-top-left-logo">
            <img
              src={`${process.env.REACT_APP_CDN_URL}${data.businessLogo}.jpg`}
              alt="Coffee Place Logo"
              className="social-voucher-logo"
            />
            <p className="social-voucher-title">Coffee Place</p>
          </div>
          <div className="social-voucher-text-section">
            <h1 className="social-voucher-place-name">{data.businessName}</h1>
            <p className="social-voucher-offer">Offer</p>
            <button className="social-voucher-visit-button">
              Come visit us
            </button>
          </div>
          <div className="social-voucher-image-mask">
            <img
              src={`${process.env.REACT_APP_CDN_URL}${data.heroImageId}.jpg`}
              alt="Hero Image"
              className="social-voucher-hero-image"
            />
          </div>
          <PoweredByMeed className="powered-by-meed" />
        </div>
        <div className="social-poster-buttons">
          <Button
            onClick={() => downloadImage("png")}
            className="social-poster-download-button"
          >
            Download PNG
          </Button>
          <Button
            onClick={() => downloadImage("jpeg")}
            className="social-poster-download-button"
          >
            Download JPEG
          </Button>
        </div>
      </div>
    </>
  );
}

export default SocialPoster;
