import { SET_ACCOUNT, SET_ACCOUNT_NAME } from "../actions/accountActionTypes";
import { LOGOUT } from "../actions/actionTypes";

/**
 * @typedef InitialState
 * @property {string|null} name
 * @property {number|null} id
 * @property {string|null} uuid
 * @property {number|null} subscription
 * @property {string|null} userAccountRole
 */

/** @type {InitialState} */
const initialState = {
  id: null,
  uuid: null,
  name: null,
  subscription: null,
  userAccountRole: null,
};

/**
 *
 * @param {InitialState} state
 * @param {any} action
 * @returns {InitialState}
 */
const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNT: {
      /** @type {import("../types").AccountAndUserRole} */
      const accountAndUserRole = action.payload;
      return {
        ...state,
        id: accountAndUserRole.account.id,
        name: accountAndUserRole.account.name,
        uuid: accountAndUserRole.account.uuid,
        subscription: accountAndUserRole.account.currentSubscription,
        userAccountRole: accountAndUserRole.userRole.name,
      };
    }
    case SET_ACCOUNT_NAME: {
      /** @type {import("../types").AccountAndUserRole} */
      const accountAndUserRole = action.payload;
      return {
        ...state,
        name: action.payload,
      };
    }
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default accountReducer;
