import axiosInstance from "./axiosIntercept";

export const fetchShortenedUrl = async (longUrl) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URL}v1/urlshortner/create`,
      {
        longUrl,
      },
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    return response.data.shortUrl;
  } catch (error) {
    if (error.response) {
      console.error("API Error:", error.response.status, error.response.data);
    } else {
      console.error("Network Error:", error.message);
    }
    throw error;
  }
};
