import {
  SET_DISCOUNT_VOUCHER,
  SET_CASH_VOUCHER,
  SET_GIVEAWAY,
  SET_SPECIAL_ADDRESS,
  SET_EVENT_PASS,
  SET_EVENT_ADDRESS,
  SET_CURRENCY,
  LOGOUT,
  RESET,
} from "../actions/actionTypes";

const initialState = {
  // Define your initial state here
  cashVoucher: null,
  discountVoucher: null,
  eventPass: null,
  eventAddress: null,
  giveaway: null,
  specialAddress: null,
  currency: "HKD",
};

const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISCOUNT_VOUCHER:
      return { ...state, discountVoucher: action.payload };
    case SET_CASH_VOUCHER:
      return { ...state, cashVoucher: action.payload };
    case SET_EVENT_PASS:
      return {
        ...state,
        eventPass: action.payload,
      };
    case SET_EVENT_ADDRESS:
      return {
        ...state,
        eventAddress: action.payload,
      };
    case SET_GIVEAWAY:
      return {
        ...state,
        giveaway: action.payload,
      };
    case SET_SPECIAL_ADDRESS:
      return {
        ...state,
        specialAddress: action.payload,
      };
    case SET_CURRENCY:
      return {
        ...state,
        currency: action.payload,
      };
    case LOGOUT:
      return initialState;
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default voucherReducer;
