import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
// npm install uuid js-cookie

//* Making the DeviceUUID
export function DeviceUUIDComponent() {
  // Attempt to get the UUID from the cookie
  let deviceId = Cookies.get("device-uuid");

  // If it doesn't exist, create one and set the cookie
  if (!deviceId) {
    deviceId = uuidv4();
    Cookies.set("device-uuid", deviceId, {
      expires: 365,
      secure: true,
      // httpOnly: true,
      sameSite: "strict",
    }); // Expires in 1 year
  }

  return deviceId;
}
