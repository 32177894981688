import React from "react";
import "./add_program_bar.css";
import { ReactComponent as PlusHeavyIcon } from "../../assets/symbols/plus-heavy.svg";
import classNames from "classnames";

/**
 *
 * @param {object} props
 * @param {import("react").MouseEventHandler<HTMLButtonElement>} props.onClick
 * @param {boolean} [props.disabledButClickable]
 * @param {import("react").ReactNode} props.children
 * @returns {import("react").ReactNode}
 */
function AddProgramBar({ onClick, disabledButClickable, children }) {
  return (
    <button
      className={classNames(
        "add-program-bar",
        disabledButClickable && "add-program-bar--disabled",
      )}
      type="button"
      onClick={onClick}
    >
      <div className="add-program-bar__preview">
        <PlusHeavyIcon className="add-program-bar__plus" />
      </div>
      <div className="add-program-bar__label">{children}</div>
    </button>
  );
}

export default AddProgramBar;
