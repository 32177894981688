import React, { useState } from "react";
import "./menu.css";
import { DASHBOARD_ID, DASHBOARD_URL } from "../../utils/constants";

type DashboardKey = keyof typeof DASHBOARD_ID;

const Menu = ({
  onDashboardSelect,
  onAnalyticsSelect,
}: {
  onDashboardSelect: (id: number, url: string) => void;
  onAnalyticsSelect: () => void; // New callback for Analytics selection
}) => {
  const [selectedDashboard, setSelectedDashboard] = useState<number | null>(
    DASHBOARD_ID.OVERVIEW,
  );

  const handleMenuClick = (dashboardKey: DashboardKey) => {
    setSelectedDashboard(DASHBOARD_ID[dashboardKey]);
    onDashboardSelect(DASHBOARD_ID[dashboardKey], DASHBOARD_URL[dashboardKey]);
  };

  const handleAnalyticsClick = () => {
    setSelectedDashboard(null); // Clear dashboard selection
    onAnalyticsSelect(); // Trigger showing the Analytics page
  };

  //console.log("selectedDashboard", selectedDashboard);
  return (
    <div className="menu_body">
      <button
        className={`menu_headings ${
          selectedDashboard === DASHBOARD_ID.OVERVIEW ? "selected" : ""
        }`}
        onClick={() => handleMenuClick("OVERVIEW")}
      >
        Overview
      </button>
      <button
        className={`menu_headings ${
          selectedDashboard === DASHBOARD_ID.MEMBERSHIP ? "selected" : ""
        }`}
        onClick={() => handleMenuClick("MEMBERSHIP")}
      >
        Membership
      </button>
      <button
        className={`menu_headings ${
          selectedDashboard === DASHBOARD_ID.VOUCHER ? "selected" : ""
        }`}
        onClick={() => handleMenuClick("VOUCHER")}
      >
        Voucher
      </button>
      <button
        className={`menu_headings ${
          selectedDashboard === DASHBOARD_ID.STAMP_CARD ? "selected" : ""
        }`}
        onClick={() => handleMenuClick("STAMP_CARD")}
      >
        Stamp Card
      </button>
      <button
        className={`menu_headings ${
          selectedDashboard === DASHBOARD_ID.QRC_LOCATION ? "selected" : ""
        }`}
        onClick={() => handleMenuClick("QRC_LOCATION")}
      >
        Location
      </button>
      <button
        className={`menu_headings ${
          selectedDashboard === null ? "selected" : ""
        }`}
        onClick={() => handleAnalyticsClick()}
      >
        Performance
      </button>
    </div>
  );
};

export default Menu;
