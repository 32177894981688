import React from "react";
import "./shop.css";
import { useLocation } from "react-router-dom";
import { getQrCodeUrlForProgramUuid } from "../../utils/utils";

/**
 * @param {object} data
 * @param {string} data.businessName
 * @param {string} data.businessLogo
 * @param {string} data.programUuid
 * @param {string} data.industry
 * @param {string} data.address
 * @param {string} data.brandColor
 * @param {string} data.size
 */
const openPosterPage = (data) => {
  const queryParams = new URLSearchParams(data).toString();

  const url = `/pdf?${queryParams}`;
  window.open(url, "_blank");
};

function Shop() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const data = {
    businessName: queryParams.get("businessName"),
    businessLogo: queryParams.get("businessLogo"),
    programUuid: queryParams.get("programUuid"),
    address: queryParams.get("address"),
    brandColor: queryParams.get("brandColor"),
    industry: queryParams.get("industry"),
    offer:
      "Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer Offer ",
  };

  return (
    <div className="shop">
      <div className="shop__viewport">
        <button
          className="shop__handler shop__handler--a4-poster"
          type="button"
          onClick={() => {
            openPosterPage({ ...data, size: "a4poster" });
          }}
        >
          A4 Poster
        </button>
        <button
          className="shop__handler shop__handler--qr"
          type="button"
          onClick={() => {
            openPosterPage({ ...data, size: "a6voucher" });
          }}
        >
          QR code
        </button>
        {/* <button
          className="shop__handler shop__handler--a4-voucher"
          type="button"
          onClick={() => {
            openPosterPage({ ...data, size: "a4voucher" });
          }}
        >
          A4 Voucher
        </button> */}
        <button
          className="shop__handler shop__handler--a5-voucher"
          type="button"
          onClick={() => {
            openPosterPage({ ...data, size: "a4voucher" });
          }}
        >
          A5 Voucher
        </button>
        <button
          className="shop__handler shop__handler--a6-voucher-b"
          type="button"
          onClick={() => {
            openPosterPage({ ...data, size: "a5voucher" });
          }}
        >
          A6 Voucher
        </button>
        <button
          className="shop__handler shop__handler--a6-voucher-a"
          type="button"
          onClick={() => {
            openPosterPage({ ...data, size: "a6voucher" });
          }}
        >
          A6 Voucher
        </button>
      </div>
    </div>
  );
}

export default Shop;
