import React, { useEffect, useRef, useState } from "react";
import "./pattern_picker.css";
import Pattern from "../Pattern/pattern";
import { PATTERNS } from "../../utils/constants";
import OuterClose from "../OuterClose/outer_close";

function PatternPicker({ onPatternSelect, prevPattern }) {
  const patternPickerRef = useRef(null);
  const [selectedPattern, setSelectedPattern] = useState();
  const [isPatternListVisible, setPatternListVisible] = useState(false);

  const togglePatternList = () => {
    setPatternListVisible(!isPatternListVisible);
  };

  const handlePatternSelect = (value) => {
    setSelectedPattern(value);
    onPatternSelect(value);
    setPatternListVisible(false);
  };

  /* Hide dropdown by Esc */
  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      setPatternListVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Adding an empty dependency array ensures the event listener is set up only once

  /* Hide dropdown by clicking outside of dropdown */
  const handleOutsideClick = (event) => {
    if (
      patternPickerRef.current &&
      !patternPickerRef.current.contains(event.target)
    ) {
      setPatternListVisible(false); // Hide the list when clicking outside of the component
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []); // Adding an empty dependency array ensures the event listener is set up only once

  return (
    <div
      className={`pattern-picker ${
        isPatternListVisible ? "pattern-picker--expanded" : ""
      }`}
      ref={patternPickerRef}
    >
      <button
        className="pattern-picker__handler"
        type="button"
        onClick={togglePatternList}
      >
        <div className="pattern-picker__preview">
          <Pattern gradient={selectedPattern ? selectedPattern : prevPattern} />
        </div>
        <span className="pattern-picker__label">
          {selectedPattern ? selectedPattern : prevPattern}
        </span>
      </button>
      <div className="pattern-picker__dropdown">
        <div className="pattern-picker__sub-heading">Select your pattern</div>
        <div className="pattern-picker__close">
          <OuterClose
            onClick={() => {
              setPatternListVisible(false);
            }}
          />
        </div>
        <div className="pattern-picker__list">
          {PATTERNS.map((item, index) => (
            <button
              className="pattern-picker__item"
              type="button"
              key={index}
              onClick={() => {
                handlePatternSelect(item);
              }}
            >
              <Pattern gradient={item} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PatternPicker;
