import React, { useState } from "react";
import "./collapse.css";
import { ReactComponent as ChevronDownIcon } from "./../../assets/symbols/chevron-down.svg";

function Collapse({ textOnHandler, children }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const collapseListener = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="collapse">
      <h2 className="collapse__heading">
        <button
          className="collapse__handler"
          type="button"
          onClick={collapseListener}
        >
          {textOnHandler}
          <ChevronDownIcon className="collapse__angle" />
        </button>
      </h2>
      {isExpanded && <div className="collapse__dropdown">{children}</div>}
    </div>
  );
}

export default Collapse;
