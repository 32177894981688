import axiosInstance from "../services/axiosIntercept";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../selectors/selectors";
import * as actions from "../actions/actions";

const useEmailUpdateRequest = () => {
  //   const dispatch = useDispatch();
  const existingMerchantEmail = useSelector(selectors.getEmail);
  const merchantName = useSelector(selectors.getName);
  const imgSha = useSelector(selectors.getProfileImageId);

  const emailUpdateRequest = (newEmail) => {
    try {
      const payload = {
        existingMerchantEmail: existingMerchantEmail,
        newEmail: newEmail,
        merchantName: merchantName,
        profileImage: imgSha,
      };

      console.log(payload);

      axiosInstance.post(
        `${process.env.REACT_APP_API_URL}v2/business/email/update/request`,
        payload,
        {
          withCredentials: true,
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
          },
        },
      );
    } catch (err) {
      console.error("Error while sending email update request!.", err);
    }
  };

  return emailUpdateRequest;
};

export default useEmailUpdateRequest;
