// hooks/useGetIndustries.js
import { useDispatch } from "react-redux";
import { getIndustries as getIndustriesAction } from "../actions/industryActions";

const useGetIndustries = () => {
  const dispatch = useDispatch();

  const getIndustries = () => {
    dispatch(getIndustriesAction());
  };

  return getIndustries;
};

/* import { useDispatch } from "react-redux";
import { fetchIndustries } from "../services/industryService";

const useGetIndustries = () => {
  const dispatch = useDispatch();

  const getIndustries = async () => {
    try {
      const industries = await fetchIndustries();
      localStorage.setItem("Industries", JSON.stringify(industries));
      dispatch({ type: "SET_INDUSTRIES", payload: industries });
    } catch (error) {
      throw error;
    }
  };

  return getIndustries;
}; */
export default useGetIndustries;
