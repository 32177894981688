import React, { useRef, useState } from "react";
import "./inner_scroll.css";
import { ReactComponent as ChevronUpTinyIcon } from "./../../assets/symbols/chevron-up-tiny.svg";
import { ReactComponent as ChevronDownTinyIcon } from "./../../assets/symbols/chevron-down-tiny.svg";

function InnerScroll({ children }) {
  const $scrollingArea = useRef(null);
  const $scrollHandler = useRef(null);
  const $topFade = useRef(null);
  const $bottomFade = useRef(null);

  const [scrolledToTop, setScrolledToTop] = useState(true);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  /* This threshold has 2 purposes:
   * 1) First it is needed sort out floating-point related issues.
   * For example this comparison:
   * $scroll.scrollHeight - $scroll.scrollTop === $scroll.clientHeight
   * will be falsy in case of: 374 - 73.75 === 300
   * So we need at least 1px or 2px of threshold
   * 2) Visually it looks better if threshold is around 10.
   * Minor shifts of user scroll or mousewheel won't affect algorithm.
   */
  const threshold = 10;

  function handleScroll() {
    if ($scrollingArea.current.scrollTop <= threshold) {
      setScrolledToTop(true);
    } else {
      setScrolledToTop(false);
    }

    if (
      $scrollingArea.current.scrollHeight - $scrollingArea.current.scrollTop <=
      $scrollingArea.current.clientHeight + threshold
    ) {
      setScrolledToBottom(true);
    } else {
      setScrolledToBottom(false);
    }
  }

  function scrollUp() {
    const currentScroll = $scrollingArea.current.scrollTop;
    const toScroll = $scrollingArea.current.offsetHeight;
    $scrollingArea.current.scrollTo({
      top: currentScroll - (4 / 5) * toScroll,
      behavior: "smooth",
    });
  }

  function scrollDown() {
    const currentScroll = $scrollingArea.current.scrollTop;
    const toScroll = $scrollingArea.current.offsetHeight;
    $scrollingArea.current.scrollTo({
      top: currentScroll + (4 / 5) * toScroll,
      behavior: "smooth",
    });
  }

  return (
    <div className="inner-scroll">
      <div
        className="inner-scroll__scroll"
        ref={$scrollingArea}
        onScroll={handleScroll}
      >
        <div className="inner-scroll__ribbon">{children}</div>
      </div>
      {!scrolledToTop && (
        <>
          <div
            ref={$topFade}
            className="inner-scroll__fade inner-scroll__fade--top"
            role="presentation"
            aria-hidden="true"
          ></div>
          <button
            ref={$scrollHandler}
            onClick={scrollUp}
            className="inner-scroll__handler inner-scroll__handler--top"
            type="button"
          >
            <ChevronUpTinyIcon className="inner-scroll__chevron" />
            <span className="visuallyhidden">Scroll up</span>
          </button>
        </>
      )}
      {!scrolledToBottom && (
        <>
          <div
            ref={$bottomFade}
            className="inner-scroll__fade inner-scroll__fade--bottom"
            role="presentation"
            aria-hidden="true"
          ></div>
          <button
            ref={$scrollHandler}
            onClick={scrollDown}
            className="inner-scroll__handler inner-scroll__handler--bottom"
            type="button"
          >
            <ChevronDownTinyIcon className="inner-scroll__chevron" />
            <span className="visuallyhidden">Scroll down</span>
          </button>
        </>
      )}
    </div>
  );
}

export default InnerScroll;
