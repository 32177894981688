import React, { useState } from "react";
import "./filter.css";
import { ReactComponent as RefreshIcon } from "../../assets/symbols/refresh.svg";
import classNames from "classnames";

/**
 * @param {object} props
 * @param {Record<string, string>} props.items
 * @param {string} props.selectedItem
 * @param {(key: string) => void} props.onSelectItem
 * @param {() => Promise<void>} props.onRefresh
 * @returns {import("react").ReactNode}
 */
function Filter({ items, onRefresh, onSelectItem, selectedItem }) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="filter">
      <div className="filter__ribbon">
        <div className="filter__bar">
          {Object.entries(items).map(([key, value]) => (
            <button
              className={classNames([
                "filter__item",
                key === selectedItem && "filter__item--selected",
              ])}
              type="button"
              disabled={isLoading}
              onClick={() => {
                onSelectItem(key);
              }}
            >
              {value}
            </button>
          ))}
        </div>
        <div className="filter__refresh">
          <button
            className="button button--almost-a-square button--secondary"
            type="button"
            disabled={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                await onRefresh();
              } finally {
                setIsLoading(false);
              }
            }}
          >
            <RefreshIcon className="button__icon" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Filter;
